import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const IMG = 'https://www.simplybuy.io/simplybuy-favicon.png'

// const LOGO_LD = `
//     {
//       "@context": "http://schema.org",
//       "@type": "Organization",
//       "url": "https://www.chalicenetwork.com/",
//       "logo": "${ IMG }"
//     }
// `

// const CORPORATE_LD = `
//   {
//     "@context": "http://schema.org",
//     "@type": "Organization",
//     "url": "https://www.chalicenetwork.com/",
//     "contactPoint": [
//       { "@type": "ContactPoint",
//         "telephone": "+1-800-535-6981",
//         "contactType": "Sales"
//       }
//     ]
//   }
// `

// const SOCIAL_LD = `
//   {
//     "@context": "http://schema.org",
//     "@type": "Organization",
//     "name": "Chalice Financial Network",
//     "url": "https://www.chalicenetwork.com/",
//     "sameAs": [
//       "https://www.facebook.com/chalicenetwork/",
//       "https://www.linkedin.com/company/chalicenetwork/",
//       "https://twitter.com/ChaliceNetwork"
//     ]
//   }
// `

function SEO ({ title, type, imageUrl, description, lang, meta }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}>
      <title>{title}</title>
      <link rel="manifest" href="/manifest.json" />
      <link rel="apple-touch-icon" href="/assets/images/simplybuy-favicon.png"></link>
      <meta name='description' content={description}/>

      {/*<script type="application/ld+json">{LOGO_LD}</script>
      <script type="application/ld+json">{CORPORATE_LD}</script>
      <script type="application/ld+json">{SOCIAL_LD}</script>*/}

      <meta property='twitter:title' content={title}/>
      <meta property='twitter:description' content={description}/>
      <meta property='og:title' content={title}/>
      <meta property='og:description' content={description}/>
      <meta property='og:type' content={type || 'website'}/>
      { imageUrl && (
        <meta property='og:image' content={imageUrl} />
      ) }
      { !imageUrl && (
        <meta property='og:image' content={IMG} />
      ) }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
