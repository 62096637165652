import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Nouislider from "@x1mrdonut1x/nouislider-react";
import "nouislider/distribute/nouislider.css";
import 'react-toastify/dist/ReactToastify.css'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import Switch from './Switch.js'
import services from '../services'
import { APIURL } from './../../constants'
import { formatShoeSizeMens } from './widgets/formats'

const START_SIZE = 9
const MIN_SIZE = 4
const MAX_SIZE = 15
const QN = 3

toast.configure()

class BootsSliders extends Component {
  constructor (props) {
    super(props)
    const td = this.props.topic_data
    this.state = {
      shoeSize: this.checkIfValue(td, 'shoeSize') ? td[QN].input_values['shoeSize'] : 0,
      start: this.checkIfValue(td, 'shoeSize') ? td[QN].input_values['shoeSize'] : 9.5,
      wide: td && td[QN].secondary_values && td[QN].secondary_values === 'True' ? 'True' : 'False',
      show_sliders: true,
      min: MIN_SIZE,
      max: MAX_SIZE,
      ref: null,
      rendering: false,
      user_info_save: false,
      initial: true
    }
  }

  checkIfValue (td, key) {
    return (td && td[QN].input_values && td[QN].input_values[key] && td[QN].input_values[key])
  }

  componentDidMount = () => {
    const td = this.props.topic_data
    if (this.props.logged_in === 'true') {
      services.get_v2(APIURL.USER_PROFILE, {params: {}}).then((response) =>{
        let user_info = response
        let calculate_needed = false
        let user_info_save = true
        if (user_info.shoe_size && !this.checkIfValue(td, 'shoeSize')) {
          user_info_save = false
        }
        this.setState({
          user_profile: true,
          start: this.checkIfValue(td, 'shoeSize') ? td[QN].input_values['shoeSize'] : false || user_info.shoe_size || 9.5,
          user_info_save: user_info_save,
          initial: false,
        })
      })
    } else if (typeof window !== "undefined") {
      let user_info = JSON.parse(window.localStorage.getItem('calculator_values'))
      if (user_info) {
        this.setState({
          initial: false,
          user_profile: true,
          start: this.checkIfValue(td, 'shoeSize') ? td[QN].input_values['shoeSize'] : false || user_info.shoeSize || 9.5,
          // calculate_needed: calculate_needed,
        })
      } else {
        this.setState({
          initial: false,
        })
      }
    }
  }

  toggleSwitch = () => {
    if (this.state.wide === 'False') {
      this.setState({wide: 'True'}, () => this.createRange(this.state.shoeSize))
    } else {
      this.setState({wide: 'False'}, () => this.createRange(this.state.shoeSize))
    }
  }

  createRange = (value) => {
    if (value[0].includes('.5')) {
      value[0] = String(Math.round(value[0] * 10) / 10)
    }
    else {
      value[0] = String(parseInt(value[0]))
    }
    if (this.state.show_sliders) {
      if (this.state.initial) {
        this.props.setWidgetValues(value, this.state.wide, {}, false, this.state.user_info_save)
      } else {
        this.props.setWidgetValues(value, this.state.wide, {shoeSize: value[0]}, false, this.state.user_info_save)

      }
    }
  }

  checkSizes1 = (value) => {
      this.setState({shoeSize: value})
      this.createRange(value)
  }


  render () {
    const {
      min,
      max,
      start,
      wide,
      rendering,
    } = this.state
    return (
      <div className='snowboard-widget'>
        <div className="calc-sliders tv">
        {rendering && (
          <div className="spinner-container">
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        )}
        {!rendering && (
            <div className={'slider-fade ' + classNames({'display-none': !this.state.show_sliders})}>
              <div className="slider-container bottom-sliders">
                <Nouislider
                  onUpdate={(e) => {this.checkSizes1(e)}}
                  step={.5}
                  range={{ min: min, max: max }}
                  start={start}
                  tooltips={formatShoeSizeMens(max, true)}
                />
                <div className="slider-label">{this.props.topic_data[1].value ==='womens' ? "Women's ": "Men's "}Shoe Size</div>
                <Switch containerClasses={"switch-container mt-sm"} notSmall={true} label={'Wide sized boots?'} checked={(wide === 'True')} switchChange={this.toggleSwitch}/>
              </div>
            </div>
        )}
        </div>
      </div>
    )
  }
}

BootsSliders.propTypes = {
  topic_data: PropTypes.object,
  goBackToQuestionX: PropTypes.func,
  logged_in: PropTypes.string

}

export default BootsSliders
