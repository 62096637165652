import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'

class FormikField extends React.Component {
  render () {
    return (
      <div className="">
        <div>
          { this.props.children }
        </div>
        <div className="text-danger" >
          <ErrorMessage name={this.props.name} />
        </div>
      </div>
    )
  }
}

FormikField.propTypes = {
  children: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  show_required: PropTypes.bool
}

export default FormikField
