import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types'
import { PAGEURL } from './../constants'
import {
  Home,
  Bulking,
  Barbell,
  ProductPicker,
  SavedResults,
  DefaultNav,
  About
} from './../components'
import Router from 'next/router'
// import { useDropdownMenu } from "react-overlays";
import Footer from './../components/common/Footer.js'
import services from './../components/services'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppContext from '../components/common/AppContext'
// const useAppContext = () => useContext(AppContext);
// import { useAppContext } from "./../pages/_app";

toast.configure()
let localStorage = null
if (typeof window !== "undefined") {
  localStorage = window.localStorage
}

function ProductPickerLayout(props) {
  console.log(props)
  const useAppContext = () => useContext(AppContext);
  const { logged_in, setAuthAndStore, handlelogin, handlelogout } = useAppContext();
  console.log(logged_in)
  // const [logged_in, set_logged_in] = useState(
  //   localStorage && localStorage.getItem('token') ? true : false
  // )
  const router = useRouter();
  // useEffect(() => {
  //   if (localStorage) {
  //     set_logged_in(localStorage.getItem('token') ? true : false)
  //   }
  // }, [])

  // const handlelogout = () => {
  //   setAuthAndStore("false")
  //   localStorage.removeItem('token');
  //   router.push('/')
  // }

  // const handlelogin = async (values, token=null) => {
  //   if (token) {
  //     localStorage.setItem('token', token);
  //     setAuthAndStore("true")
  //   } else {
  //     const response = await services.post_v2("token-auth/", values)
  //     if (response.token) {
  //       localStorage.setItem('token', response.token);
  //       setAuthAndStore("true")
  //     } else {
  //       return false
  //     }
  //   }
  //   toast('You are logged in!')
  //   // return(response)
  // };

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        // logged_in: logged_in,
        // handlelogin: handlelogin,
        // handlelogout: handlelogout,
        router: router,
        // test: 'test'
      });
    }
    return child;
  });

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH}>
      <div>
        <DefaultNav
          handlelogout={handlelogout}
          handlelogin={handlelogin}
          logged_in={logged_in}
          router={router}
        />
          <div>
            {childrenWithProps}
          </div>
        <Footer extraPadding={true} router={router}/>
      </div>
    </GoogleOAuthProvider>
  )
}

// DefaultLayout.propTypes = {
//   history: PropTypes.object,
//   match: PropTypes.object,
//   // store: PropTypes.object
// }

export default ProductPickerLayout
