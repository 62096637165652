
const formatHeight = (max) => {
  return {
    to: function(value) {
      let feet = Math.floor(value / 12)
      let inches = value % 12
      let height = feet + "'" + inches + '"' + (value === 76 ? "+" : "")
      return height
    },
    from: function(value) {return value}
  }
}

const formatHeightMetric = (max) => {
  return {
    to: function(value) {
      let height = value + "cm" + (value === 194 ? "+" : "")
      return height
    },
    from: function(value) {return value}
  }
}

const formatWeight = (max) => {
  return {
    to: function(value) {
      value = Math.round(value)
      return value + "lb" + (value === 270 ? "+" : "")
    },
    from: function(value) {return value}
  }
}

const formatWeightMetric = (max) => {
  return {
    to: function(value) {
      value = Math.round(value)
      return value + "kg" + (value === 120 ? "+" : "")
    },
    from: function(value) {return value}
  }
}

const formatShoeSizeMens = (max, noMax = false) => {
  if (noMax) {
    return {
      to: function(value) {
        value = Math.round(value * 10) / 10
        return value + " (US)"
      },
      from: function(value) {return value}
    }
  }
  return {
    to: function(value) {
      return value + " (US)" + (value === 14 ? "+" : "")
    },
    from: function(value) {return value}
  }
}

const formatCM = (max) => {
  return {
    to: function(value) {
      value = Math.round(value)
      return value + "cm" + (value === max ? "+" : "")
    },
    from: function(value) {return value}
  }
}

const formatMM = (max) => {
  return {
    to: function(value) {
      value = Math.round(value)
      return value + "mm" + (value === max ? "+" : "")
    },
    from: function(value) {return value}
  }
}

const formatIN = (max) => {
  return {
    to: function(value) {
      value = Math.round(value)
      return value + '"' + (value === max ? "+" : "")
    },
    from: function(value) {return value}
  }
}

export {
  formatCM,
  formatShoeSizeMens,
  formatHeightMetric,
  formatMM,
  formatWeightMetric,
  formatWeight,
  formatHeight,
  formatIN
}

// const formatCM = {
//   to: function(value) {
//     value = Math.round(value)
//     return value + "cm" + (value === max ? "+" : "")
//   },
//   from: function(value) {return value}
// }


// const formatMM = (max) => {
//   return {
//     to: function(value) {
//       value = Math.round(value)
//       return value + "mm" + (value === widthMax ? "+" : "")
//     },
//     from: function(value) {return value}
//   }
// }
