import React from 'react';
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import services from '../services'
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types'
import {CONSTANTS} from '../../constants'
// import { inject, observer } from 'mobx-react'
import { APIURL, PAGEURL } from './../../constants'
import { add_unit, getToastMessage, getCostSymbols } from '../utils'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import BottomNav from './BottomNav'
import Switch from './Switch.js'
import LazyImg from './LazyImg.js'
import ProductSlider from './ProductSlider.js'
const SaveResultsModal = dynamic(() => import('./SaveResultsModal.js'), {
  ssr: false
});
import TvSliders from './TvSliders.js'
import SkiSizeWidget from './SkiSizeWidget.js'
import BootsWidget from './BootsWidget.js'
import SnowboardBindingSizeWidget from './SnowboardBindingSizeWidget.js'
import SnowboardSizeWidget from './SnowboardSizeWidget.js'
import MountainBikeSizeWidget from './MountainBikeSizeWidget.js'


// const TvSliders = dynamic(() => import('./TvSliders.js'), {
//   ssr: false
// });
// const SkiSizeWidget = dynamic(() => import('./SkiSizeWidget.js'), {
//   ssr: false
// });
// const SnowboardBindingSizeWidget = dynamic(() => import('./SnowboardBindingSizeWidget.js'), {
//   ssr: false
// });
// const SnowboardSizeWidget = dynamic(() => import('./SnowboardSizeWidget.js'), {
//   ssr: false
// });
// const MountainBikeSizeWidget = dynamic(() => import('./MountainBikeSizeWidget.js'), {
//   ssr: false
// });
// const BootsWidget = dynamic(() => import('./BootsWidget.js'), {
//   ssr: false
// });

const RadarComponent = dynamic(() => import('./Radar.js'), {
  ssr: false
});
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsQuestionCircleFill } from "@react-icons/all-files/bs/BsQuestionCircleFill";
import { BsStopwatch } from "@react-icons/all-files/bs/BsStopwatch";
import { BsQuestionCircle } from "@react-icons/all-files/bs/BsQuestionCircle";
import { BsFillCaretRightFill } from "@react-icons/all-files/bs/BsFillCaretRightFill";
import { BsFillCaretLeftFill } from "@react-icons/all-files/bs/BsFillCaretLeftFill";
import { BsQuestionSquare } from '@react-icons/all-files/bs/BsQuestionSquare'
import { BsInfoCircle } from '@react-icons/all-files/bs/BsInfoCircle'
import { BsInfoCircleFill } from '@react-icons/all-files/bs/BsInfoCircleFill'
import { FiPlus } from '@react-icons/all-files/fi/FiPlus'
import { FiMinus } from '@react-icons/all-files/fi/FiMinus'
import { BiCoffeeTogo } from '@react-icons/all-files/bi/BiCoffeeTogo'
import { IoMdRemoveCircle } from '@react-icons/all-files/io/IoMdRemoveCircle'
import { GoLock } from '@react-icons/all-files/go/GoLock'
import { FaSnowboarding } from '@react-icons/all-files/fa/FaSnowboarding'
import { FaStar } from '@react-icons/all-files/fa/FaStar'
import { GiCheckeredFlag } from "@react-icons/all-files/gi/GiCheckeredFlag"
import { GiCheckMark } from "@react-icons/all-files/gi/GiCheckMark"
import { IoMdOptions } from "@react-icons/all-files/io/IoMdOptions"
import { IoMdFunnel } from "@react-icons/all-files/io/IoMdFunnel"
import { BsFillGrid3X3GapFill } from '@react-icons/all-files/bs/BsFillGrid3X3GapFill'
import Image from 'next/image'
import Link from 'next/link'
// import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { logError } from '../Logger.js'
// const logError = dynamic(() => import('../Logger.js'), {
//   ssr: false
// });
// const logInfo = dynamic(() => import('../Logger.js'), {
//   ssr: false
// });
// console.log(logError)

const ReactTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false
});

// import { useRouter } from 'next/router'
// if (typeof window === 'undefined') {
//   import FingerprintJS from '@fingerprintjs/fingerprintjs'
// }
//
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
//
// Sentry.init({
//   dsn: "https://ffe23113959946faaa986b4ccd7b9b81@o1032396.ingest.sentry.io/5999351",
//   integrations: [new Integrations.BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.5,
// })

//const queryString = require('query-string')
toast.configure()

let fpPromise = null
if (typeof window !== "undefined") {
import('@fingerprintjs/fingerprintjs')
  .then((module) => {
    fpPromise = module.load()
    // Do something with the module.
  });
// const fpPromise = FingerprintJS.load()
}
const STATIC_PRODUCT_ROWS = 3

class Result extends React.Component {
  static propTypes = {
    results: PropTypes.array,
    resultType: PropTypes.string,
    prevValue: PropTypes.func,
    nextValue: PropTypes.func,
    position: PropTypes.number,
    views: PropTypes.array,
    addProductView: PropTypes.func,
    pickbutton: PropTypes.string,

  }

  componentDidMount() {
      const { results, resultType, views, position, pickbutton } = this.props

      let result = this.props.results[position]
      let product_id = result.props['data-product-id']
      let isHighend = true ? resultType === 'highend' : false
      if ((pickbutton === 'highend' && isHighend) || (pickbutton == 'value' && !isHighend)) {
        if (views.indexOf(product_id) === -1) {
          this.props.addProductView(product_id, isHighend)
        }
      }
  }

  componentDidUpdate () {
    const { results, resultType, views, position, pickbutton } = this.props
    let result = this.props.results[position]
    let product_id = result.props['data-product-id']
    let isHighend = true ? resultType === 'highend' : false
    if ((pickbutton === 'highend' && isHighend) || (pickbutton == 'value' && !isHighend)) {
      if (views.indexOf(product_id) === -1) {
        this.props.addProductView(product_id, isHighend)
      }
    }
  }

  render () {
    const { results, resultType, views } = this.props
    let wrapperClass = ""
    let containerClass = ""
    let pick_label = ""
    let isHighend = false
    if (resultType === 'not-top') {
      containerClass = 'result-container not-top'
    } else if (resultType === 'value') {
      pick_label = "Value Picks"
      wrapperClass = 'top-picks-wrapper'
      containerClass = 'result-container top-picks'
    } else if (resultType === 'highend') {
      pick_label = "High-end Picks"
      wrapperClass = 'top-picks-wrapper high-end'
      containerClass = 'result-container top-picks'
      isHighend = true
    }
    let count = (this.props.position + 1) + " / " + this.props.results.length
    let result = this.props.results[this.props.position]
    let product_id = result.props['data-product-id']

    return (
      <div>
        <div className={wrapperClass}>
          <div className={'pick-label'}>
            <div>
              {this.props.results.length > 1 && (
                <BsFillCaretLeftFill className={'clickable '} onClick={() => {this.props.prevValue(product_id)}}/>
              )}
            </div>
            <div>
              {pick_label}
            </div>
            <div>
              {count}
            </div>
            <div>
              {this.props.results.length > 1 && (
                <BsFillCaretRightFill className={'clickable '} onClick={() => {this.props.nextValue(product_id)}}/>
              )}
            </div>
          </div>
          <div className={containerClass}>
            {result}
          </div>
          <div className="bottom-flip-through mt-sm">
            {this.props.results.length > 1 && (
              <BsFillCaretLeftFill onClick={() => {this.props.prevValue(product_id)}}/>
            )}
            <div>
            </div>
            <div>
              {count}
            </div>
            <div>
            </div>
            {this.props.results.length > 1 && (
              <BsFillCaretRightFill onClick={() => {this.props.nextValue(product_id)}}/>
            )}
          </div>
        </div>
      </div>
    )
  }
}


class BestResult extends React.Component {
  static propTypes = {
    best_result: PropTypes.object,
    high_end_result: PropTypes.object,
    toggleShowMoreResults: PropTypes.func,
    toggleShowMoreHighendResults: PropTypes.func,
    toggleShowMoreValueResults: PropTypes.func,
    exact_matches: PropTypes.bool,
    display_value_products: PropTypes.array,
    display_highend_products: PropTypes.array,
    toggleEditFilters: PropTypes.func,
    data_uuid: PropTypes.string
  }

  constructor (props) {
    super(props)
    // this.toggleShowMoreResults = this.toggleShowMoreResults.bind(this)
    this.state = {
      index: 0,
      pickbutton: 'value',
      value_position: 0,
      highend_position: 0,
      views: [],
      switchClicked: false,
    }
  }

  addProductView = (product_id, highend) => {
    let number_of_products = this.props.display_value_products.length + this.props.display_highend_products.length
    services.post_v2(APIURL.ADD_PRODUCT_VIEW, {
      data_uuid: this.props.data_uuid,
      product_id: product_id,
      highend: highend,
      number_of_products: number_of_products
    })
    let views = this.state.views
    views.push(product_id)
    this.setState({views: views})
  }

  nextResult = () => {
    this.setState({index: this.state.index + 1})

  }

  previousResult = () => {
    this.setState({index: this.state.index - 1})
  }

  nextValue = (product_id) => {
    if (this.state.value_position + 1 === this.props.display_value_products.length) {
      this.setState({value_position: 0})
    } else {
      this.setState({value_position: this.state.value_position + 1})
    }
  }

  prevValue = (product_id) => {
    if (this.state.value_position - 1 === -1) {
      this.setState({value_position: this.props.display_value_products.length - 1})
    } else {
      this.setState({value_position: this.state.value_position - 1 })
    }
  }

  nextHighend= () => {
    if (this.state.highend_position + 1 === this.props.display_highend_products.length) {
      this.setState({highend_position: 0})
    } else {
      this.setState({highend_position: this.state.highend_position + 1})
    }
  }

  prevHighend= () => {
    if (this.state.highend_position - 1 === -1) {
      this.setState({highend_position: this.props.display_highend_products.length - 1})
    } else {
      this.setState({highend_position: this.state.highend_position - 1 })
    }
  }

  togglePick = () => {
    if (this.state.pickbutton === 'value') {
      this.setState({pickbutton: 'highend'})
    } else {
      this.setState({pickbutton: 'value'})
    }
    this.setState({switchClicked: true})
  }

  render () {
    const {
      exact_matches,
      // toggleShowMoreValueResults,
      // toggleShowMoreHighendResults,
      display_value_products,
      display_highend_products,
      data_uuid,
      related_button_container
      // show_more_value,
      // show_more_highend
    } = this.props
    let best_results = display_value_products
    let high_end_results = display_highend_products
    // let queue = [
    //   {result_type: 'value', result: best_result},
    //   {result_type: 'highend', result: high_end_result}
    // ]

    let queue = {
      'value': {result_type: 'value', results: best_results, position: this.state.value_position, nextValue: this.nextValue, prevValue: this.prevValue},
      'highend': {result_type: 'highend', results: high_end_results, position: this.state.highend_position, nextValue: this.nextHighend, prevValue: this.prevHighend}
    }
    let results_count = high_end_results.length + best_results.length
    return (
      <div>
        {best_results.length > 0 && (
          <div>
            <div className="top-pick-text hidden-md-up mt-sm">{results_count+" Results For You!"}</div>
            <div className="top-pick-text hidden-sm-down">Top Picks For You</div>
            <div></div>
            {!exact_matches && (
              <div>
                <div className="no-exact">No exact matches found. To tailor these results use the lock buttons for your most desired filters.</div>
                <div className="btn-center-container display-small">
                  <button
                    className={"action-btn-gld continue-btn btn-m10 small "}
                    onClick={
                      () => {
                        this.props.toggleEditFilters()
                      }
                      }>
                    {"Unlock / Edit Filters"}
                  </button>
                </div>
              </div>
            )}
            <div className="desktop-top-picks">
              <div className="more-results ">
                <div>
                  <Result
                    prevValue={queue['value']['prevValue']}
                    nextValue={queue['value']['nextValue']}
                    position={queue['value']['position']}
                    results={queue['value']['results']}
                    resultType={queue['value']['result_type']}
                    views={this.state.views}
                    addProductView={this.addProductView}
                    pickbutton={null}
                  />
                </div>
                {high_end_results.length > 0 && (
                  <div>
                    <Result
                      prevValue={queue['highend']['prevValue']}
                      nextValue={queue['highend']['nextValue']}
                      position={queue['highend']['position']}
                      results={queue['highend']['results']}
                      resultType={queue['highend']['result_type']}
                      views={this.state.views}
                      addProductView={this.addProductView}
                      pickbutton={null}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mobile-top-picks">
              {high_end_results.length > 0 && (
                <div className="switch-ctn-container">
                  <div className={"switch-label left " + classNames({'not-checked': this.state.pickbutton === 'highend'})}>Value</div>
                  <Switch
                    label={''}
                    checked={this.state.pickbutton === 'highend'}
                    offColor={"#06bef0"}
                    onColor={"#f08706"}
                    className={classNames({'pulse': this.state.switchClicked === false})}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    switchChange={() => {this.togglePick()}}
                  />
                  <div className={"switch-label right " + classNames({'not-checked': this.state.pickbutton === 'value'})}>High-end</div>
                </div>


              )}
              <div className="more-results ">
                <Result
                  prevValue={queue[this.state.pickbutton]['prevValue']}
                  nextValue={queue[this.state.pickbutton]['nextValue']}
                  position={queue[this.state.pickbutton]['position']}
                  results={queue[this.state.pickbutton]['results']}
                  resultType={queue[this.state.pickbutton]['result_type']}
                  views={this.state.views}
                  addProductView={this.addProductView}
                  pickbutton={this.state.pickbutton}
                />
              </div>
              {high_end_results.length > 0 && (
                <div className="mt-lg switch-ctn-container">
                  <div className={"switch-label left " + classNames({'not-checked': this.state.pickbutton === 'highend'})}>Value</div>
                  <Switch
                    label={''}
                    checked={this.state.pickbutton === 'highend'}
                    offColor={"#06bef0"}
                    onColor={"#f08706"}
                    className={classNames({'pulse': this.state.switchClicked === false})}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    switchChange={() => {this.togglePick()}}
                  />
                  <div className={"switch-label right " + classNames({'not-checked': this.state.pickbutton === 'value'})}>High-end</div>
                </div>
              )}
            </div>
            <div className="ta-center pb-md buy-coffee hidden-sm-down">
              <a href="https://www.buymeacoffee.com/simplybuy" className="standard-link">Enjoying simplybuy? Buy the developer a coffee.</a> <BiCoffeeTogo href="https://www.buymeacoffee.com/simplybuy"/>
            </div>
          </div>
        )}
      {best_results.length === 0 && (
        <div>
          <div className="no-exact">
            We could not find any matches within the locked&nbsp;
            <GoLock className={"lock-button lock-inline locked"}/> filters.
            Unlock filters or edit filters to see results.
          </div>
          <div className="btn-center-container display-small">
            <button
              className={"action-btn-gld continue-btn btn-m10 small " + classNames({'blue': this.state.pickbutton === 'highend'})}
              onClick={
                () => {
                  this.props.toggleEditFilters()
                }
                }>
              {"Unlock / Edit Filters"}
            </button>
          </div>
        </div>
      )}
      </div>
    )
  }
}

class Results extends React.Component {
  static propTypes = {
    products: PropTypes.array,
    product_type: PropTypes.array
  }

  render () {
    const { products } = this.props
    const display = []
    for (const product in products) {
      display.push(<Result key={'result1'} result={products[product]} resultType={this.props.product_type}/>)
    }
    return (
      <div className="more-results">
        {display}
      </div>
    )
  }
}

class Question extends React.Component {
  static propTypes = {
    logged_in: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      topic_data,
      current_question,
      show_more_options,
      product_slug,
      recommended_column_num,
      recommended_column,
      nextQuestion,
      makeSelection,
      makeSelectionMultiple,
      setWidgetValues,
      goBackToQuestionX,
      input_values,
      topic_status,
      related_button_container,
      topic_name,
      topic_name_plural,
      slider_data,
      goToSite,
      data_uuid
    } = this.props
    let num_questions = Object.keys(topic_data).length
    if (current_question !== 0 && !(current_question in topic_data)) {
      return null
    }
    return (
      <div>
        {current_question === 0 && (
          <div className="">
            <h1 className="question-title">
              {topic_status === 'hidden'? [<span key="beta" className="beta-tag">Beta</span>] :''}{topic_name} Picker
            </h1>
            {topic_status === 'hidden' && (
              <div className="disclaimer">Beta: You are one of the first to test this product. Use the Contact Us form if you have any feedback you&#39;d like to share.</div>
            )}
            <ProductSlider
              slider_data={this.props.slider_data}
              goToSite={this.props.goToSite}
              data_uuid={this.props.data_uuid}/>
            <div className="pp-symbols">
              <div className="symbol">
                <div className="icon-wrapper">
                  <BsQuestionCircle/>
                </div>
                <div className="stat-block">
                  <div className="">{num_questions} Questions</div>
                </div>
              </div>
              <div className="symbol">
                <div className="icon-wrapper">
                  <BsStopwatch/>
                </div>
                <div className="stat-block">
                  <div className="">2 Minutes</div>
                </div>
              </div>
              <div className="symbol">
                <div className="icon-wrapper">
                  <GiCheckeredFlag/>
                </div>
                <div className="stat-block">
                  <div className="">Perfect {topic_name_plural.toLowerCase()} for you!</div>
                </div>
              </div>
            </div>
            <div className="btn-center-container">
              <button className="action-btn-gld continue-btn" onClick={() => nextQuestion()}>Start</button>
            </div>
            {related_button_container.length > 0  ? <div className="related-products-black starter mt-lg">{related_button_container}</div> : null}
            {/*<div className='p-sm mt-lg'>
              <div className="psg-container ">
                <p className='picker-description'>
                    This product picker clarify the practical impact of each option, empowering you to make well-informed decisions.
                </p>
              </div>
            </div>
          */}
          </div>
        )}
        {current_question > 0 && (
          <div className="w-100">
            <div className="question-title">{topic_data[current_question].question_title}</div>
            {topic_data[current_question].widget && (
              <div>
              {topic_data[current_question].widget === 'snowboard_size' && (
                <SnowboardSizeWidget
                  topic_data={topic_data}
                  setWidgetValues={setWidgetValues}
                  goBackToQuestionX={goBackToQuestionX}
                  inputValues={input_values}
                  logged_in={this.props.logged_in}/>
              )}
              {topic_data[current_question].widget === 'ski_size' && (
                <SkiSizeWidget
                  topic_data={topic_data}
                  setWidgetValues={setWidgetValues}
                  goBackToQuestionX={goBackToQuestionX}
                  inputValues={input_values}
                  logged_in={this.props.logged_in}/>
                )
              }
              {topic_data[current_question].widget === 'mountain_bike_size' && (
                <MountainBikeSizeWidget
                  topic_data={topic_data}
                  setWidgetValues={setWidgetValues}
                  goBackToQuestionX={goBackToQuestionX}
                  inputValues={input_values}
                  makeSelectionMultiple={makeSelectionMultiple}
                  logged_in={this.props.logged_in}/>
                )
              }
              {topic_data[current_question].widget === 'snowboard_binding_size' && (
              <SnowboardBindingSizeWidget
                  topic_data={topic_data}
                  setWidgetValues={setWidgetValues}
                  goBackToQuestionX={goBackToQuestionX}
                  inputValues={input_values}
                  makeSelectionMultiple={makeSelectionMultiple}
                  logged_in={this.props.logged_in}/>
                )
              }
              {topic_data[current_question].widget === 'tv_slider' && (
                <TvSliders
                  topic_data={topic_data}
                  setWidgetValues={setWidgetValues}
                  goBackToQuestionX={goBackToQuestionX}
                  inputValues={input_values}
                  logged_in={this.props.logged_in}/>
              )}
              {topic_data[current_question].widget === 'snowboard_boots_size' && (
                <BootsWidget
                  topic_data={topic_data}
                  setWidgetValues={setWidgetValues}
                  goBackToQuestionX={goBackToQuestionX}
                  inputValues={input_values}
                  logged_in={this.props.logged_in}/>
              )}
              </div>
            )}
            {topic_data[current_question].top_image && (
              <div className="lazy-wrapper top-image">
                <LazyImg
                  src={`/images/products/${product_slug}/questions/${topic_data[current_question].top_image}`}
                  effect={'blur'}
                  width={topic_data[current_question].question_image_width ? String(topic_data[current_question].question_image_width)+'px' : '100%'}
                  height={topic_data[current_question].question_image_height ? String(topic_data[current_question].question_image_height)+'px' : 'auto'}
                  classNames='r-img'
                />
              </div>
            )}
            <div className="w-100" key={current_question}>
              <div className={"multiple-select " + classNames({'display-none': (topic_data[current_question].question_type !== 'multiple_select' && topic_data[current_question].question_type !== 'related_table_multiple' || topic_data[current_question].widget)})}>MULTIPLE SELECT</div>
              <div className={"multiple-select " + classNames({'display-none': topic_data[current_question].question_type !== 'or_greater'})}>SELECT MINIMUM</div>

              <div key={current_question} className={"calc-options-container " + classNames(
                  {
                    'mb-none': topic_data[current_question].options.length === 0,
                    'calc-options-1': topic_data[current_question].options.length === 1,
                    'calc-options-2': topic_data[current_question].options.length === 2,
                    'calc-options-3': topic_data[current_question].options.length === 3
                  }
                )
              }>
                {topic_data[current_question].options.map((option_data, index) => {
                  return (
                    <Option
                      key={index}
                      topic_data={topic_data}
                      question_data={topic_data[current_question]}
                      option_data={option_data}
                      product_slug={product_slug}
                      current_question={current_question}
                      question_type={topic_data[current_question].question_type}
                      recommended_column_num={recommended_column_num}
                      recommended_column={recommended_column}
                      makeSelection={makeSelection}
                      makeSelectionMultiple={makeSelectionMultiple}
                      show_more_options={show_more_options}
                      selected_options={topic_data[current_question].value}
                      option_image_height={topic_data[current_question].option_image_height}
                      option_image_width={topic_data[current_question].option_image_width}/>
                  )}
                )}
              </div>
              {topic_data[current_question].show_more && !show_more_options && (
                <div className="show-more pt-none" onClick={
                  () => { this.props.toggleShowMoreOptions() }}>
                    <FiPlus className="plus-minus"/>
                    Show More Options
                </div>
              )}
              {topic_data[current_question].show_more && show_more_options && (
                <div className="show-more pt-none" onClick={
                  () => { this.props.toggleShowMoreOptions() }}>
                    <FiMinus className="plus-minus"/>
                    Show Less Options
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

class Option extends React.Component {
  static propTypes = {
    // products: PropTypes.array
  }

  render() {
    const {
      option_data,
      topic_data,
      question_data,
      product_slug,
      current_question,
      question_type,
      recommended_column_num,
      recommended_column,
      makeSelection,
      makeSelectionMultiple,
      show_more_options,
      selected_options,
      option_image_width,
      option_image_height } = this.props

    const option_value = option_data.value
    const is_show_more_option = option_data.show_more
    let option_img = null
    let option_count_id = 'option-count-'+option_value
    if (option_value === 'True') {
      option_count_id = 'option-count-true'
    } else if (option_value === 'False') {
      option_count_id = 'option-count-false'
    }
    let recommendations = CONSTANTS.RECOMMENDED_SELECTIONS[product_slug]
    let show_tip = CONSTANTS.RECOMMENDED_SELECTIONS[product_slug]["show_tip"]
    let alternate = CONSTANTS.RECOMMENDED_SELECTIONS[product_slug]["alternate"]

    let user_type = topic_data[recommended_column_num]['value']
    if (alternate && topic_data[alternate.question_number]['value'] === alternate.value) {
      user_type = alternate.value
    }
    let is_recommended = false
    if ((recommendations[user_type] &&
         recommendations[user_type][question_data.column] &&
         recommendations[user_type][question_data.column].indexOf(option_value) > -1)
         || (question_data.column === recommended_column && recommendations[recommended_column].indexOf(option_value) > -1)) {
        if (question_data.column !== 'ability_level') {
          is_recommended = true
        }
      }

    if (option_data.image) {
      const windowWidth = window.innerWidth;
      let option_image_width1 = option_image_width
      let option_image_height1 = option_image_height
      if (windowWidth < 814) {
        option_image_width1 = option_image_width1 * .75
        option_image_height1 = option_image_height1 * .75
      }
      option_img = (
        <LazyImg
          width={option_image_width1 + 'px'}
          height={option_image_height1 + 'px'}
          effect={"opacity"}
          src={`/images/products/${product_slug}/options/${option_data.image}`}
          classNames={'image-center'}/>
      )
    }
    return (
      <div
        key={option_data.value + '-option-container'}
        className={
          "m-xs " + classNames(
            {'display-none': is_show_more_option && !show_more_options && !(selected_options && selected_options.includes(option_data.value))}
          )
        }>
        {option_img}
        <div className={"option-container " + classNames({'recommended': is_recommended})}>
        <div
          key={'question-'+current_question+option_value}
          id={option_value}
          className={"calc-options with-hover " + classNames({'any-option': option_value === '0', 'small-font': option_data.small_font})}
          onClick={(e) => {
            if (['multiple_select', 'related_table_multiple'].indexOf(question_type) > -1) {
              makeSelectionMultiple(e, option_value)
            } else {
              makeSelection(e, option_value)
            }
          }}>
          <div className="option-text">{option_data.text}</div>
          <div className="option-count">
            <div className="cost-symbols">{getCostSymbols(option_data.cost)}</div>
            <div id={option_count_id}>{}</div>
          </div>
        </div>
        {(
          (
            is_recommended &&
            recommendations[user_type] &&
            recommendations[user_type][question_data.column] &&
            recommendations[user_type][question_data.column].indexOf(option_value) > -1) ||
            (
              question_data.column !== 'ability_level' &&
              question_data.column === recommended_column &&
              recommendations[recommended_column].indexOf(option_value) > -1
            )
        ) && (
          <div className="tool-tip-container recommended">
            Popular Pick {current_question === show_tip &&
                          recommendations[user_type][question_data.column].includes(option_value) &&
                          recommendations[user_type][question_data.column].indexOf(option_value) === 0 && (
                            <div className=""><BsQuestionCircleFill data-tip data-for='recommended-tt' className="ml-xs clickable"/>
                              <ReactTooltip className='pp-tooltips' id='recommended-tt' type='' effect='solid'>
                                <div className="mb-md">Popular picks are determined by your previous selections.</div>
                              </ReactTooltip>
                            </div>
                          )}
          </div>
        )}
        </div>
      </div>
  )}
}


class ProductPicker extends React.Component {
  static propTypes = {
    router: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.toggleShowMoreResults = this.toggleShowMoreResults.bind(this)
    this.toggleShowMoreHighendResults = this.toggleShowMoreHighendResults.bind(this)
    this.toggleShowMoreValueResults = this.toggleShowMoreValueResults.bind(this)
    this.toggleShowMoreOptions = this.toggleShowMoreOptions.bind(this)
    this.makeSelection = this.makeSelection.bind(this)
    this.makeSelectionMultiple = this.makeSelectionMultiple.bind(this)
    this.showSaveResultsModal = this.showSaveResultsModal.bind(this)
    this.getAlertsClick = this.getAlertsClick.bind(this)
    this.setState = this.setState.bind(this)
    this.state = {
      topic_data: {},
      questions: [],
      current_question: 0,
      current_selection: null,
      next_disabled: false,
      savings: null,
      tier_needed: 1,
      data_uuid: null,
      results_page: false,
      mounting: true,
      current_selections: null,
      is_advisor: true,
      old_savings: 0,
      product_slug: this.props.router.query.slug,
      matching_products: null,
      show_more: false,
      show_save_results_modal: false,
      results_url: null,
      close_sidebar: false,
      options_data: {},
      extras_added: false,
      show_constants: true,
      exact_matches: true,
      recommended_user_type: null,
      show_tips: false,
      recommended_column: null,
      show_more_options: false,
      show_more_highend: false,
      show_more_value: false,
      secondary_selections: null,
      input_values: null,
      referrer: '',
      source: '',
      campaign: '',
      topic_status: null,
      alert_ids: [],
      sidebar_toggle: false,
      description_toggle_bool: false,
      related_products_toggle_bool: false,
      edit_filters_toggle_bool: false,
      related_button_container: [],
      path: this.props.router.pathname.replace('[slug]/[question]', ''),
      switchClicked: false,
    }
  }


  add_recommendations(current_question) {
    if (current_question !== 0) {
      try {
        const { topic_data, recommended_column, recommended_column_num } = this.state
        let recommendations = CONSTANTS.RECOMMENDED_SELECTIONS[this.state.product_slug]
        let show_tip = CONSTANTS.RECOMMENDED_SELECTIONS[this.state.product_slug]["show_tip"]
        if (topic_data[current_question].column === recommended_column && recommended_column !== 'ability_level'){
          if (recommendations[recommended_column] && [topic_data[current_question].column]) {
            try {
              for(const index in recommendations[recommended_column]){
                var e = document.getElementById(recommendations[recommended_column][index])
                if (parseInt(index) === 0 && current_question===show_tip) {
                  e.parentNode.classList.add('recommended-with-tooltip')
                } else {
                  e.parentNode.classList.add('recommended')
                }
              }
            } catch (e) {
              logError(e, "Try adding recommended tool-tip")
            }
          }
        }
        if (recommendations[topic_data[recommended_column_num]['value']] && [topic_data[current_question].column]) {
          //if there are not recommendations and no selected options disable next
          if(!recommendations[topic_data[recommended_column_num]['value']][topic_data[current_question].column]) {
            if(!topic_data[current_question].value) {
              this.setState({next_disabled: true})
            } else {
              this.setState({next_disabled: false})
            }
          } else {
            this.setState({next_disabled: false})
          }
        } else if (topic_data[current_question].column !== recommended_column && !topic_data[current_question].value) {
          this.setState({next_disabled: true})
        } else if (recommendations[recommended_column].length === 0) {
          this.setState({next_disabled: true})
        }
      } catch (err) {
        logError(err, "Try add_recommendations")
      }
    }
  }

  getFingerPrint = async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    const visitorId = result.visitorId
    return visitorId
  }

  toggleShowMoreResults () {
    this.setState({show_more: !this.state.show_more})
  }

  toggleShowMoreHighendResults () {
    this.setState({show_more_highend: !this.state.show_more_highend})
  }

  toggleShowMoreValueResults () {
    this.setState({show_more_value: !this.state.show_more_value})
  }

  toggleShowMoreOptions (show=false) {
    if (show) {
      this.setState({show_more_options: true})
    } else {
      this.setState({ show_more_options: !this.state.show_more_options })
    }
  }

  makeSelectionMultiple (e, value, widgetValues=null, secondary_values=null, input_values=null, select_buttons=null) {
    try{
      const {
        topic_data,
        current_question,
        recommended_column_num,
        product_slug,
        // current_selections
      } = this.state
      let recommendations = CONSTANTS.RECOMMENDED_SELECTIONS[product_slug]
      if (widgetValues) {
        this.setState({
          current_selections: widgetValues,
          secondary_selections: secondary_values,
          input_values: input_values,
          next_disabled: false,
        })
        if (select_buttons) {
          const current_selections = widgetValues
          topic_data[current_question].value = current_selections
          var selected_option = document.getElementsByClassName('selected')
          while(selected_option.length > 0){
            selected_option[0].classList.remove('selected');
            selected_option = document.getElementsByClassName('selected')
          }
          for (const index in widgetValues) {
            const option = document.getElementById(String(widgetValues[index]))
            option.classList.add('selected')
          }
          this.setState({ topic_data: topic_data, current_selections: current_selections })
        }
      } else if (!topic_data[current_question].widget || !widgetValues) {
        if (e) {
          if(!(e.target.hasAttribute('data-tip') || e.target.hasAttribute('fill-rule'))){
            // let current_selections = topic_data[current_question].value || []
            let current_selections = []
            if (topic_data[current_question].value) {
              current_selections = [...topic_data[current_question].value]
            }
            if (value === '0') {
              var selected_option = document.getElementsByClassName('selected')
              while(selected_option.length > 0){
                selected_option[0].classList.remove('selected')
                selected_option = document.getElementsByClassName('selected')
              }
              e.currentTarget.classList.add('selected')
              current_selections = [value]
              topic_data[current_question].value = current_selections

            } else if (e.currentTarget.classList.contains('selected')) {
              e.currentTarget.classList.remove('selected')
              const index = current_selections.indexOf(value)
              if (index > -1) {
                current_selections.splice(index, 1)
              }
              topic_data[current_question].value = current_selections
            } else {
              e.currentTarget.classList.add('selected')
              current_selections.push(value)
              topic_data[current_question].value = current_selections
              let selected_option = document.getElementsByClassName('any-option')
              if (selected_option.length > 0) {
                selected_option[0].classList.remove('selected')
              }
              const index = current_selections.indexOf('0')
              if (index > -1) {
                current_selections.splice(index, 1)
              }
              // while(selected_option.length > 0){
                // selected_option[0].classList.remove('selected')
                // selected_option = document.getElementsByClassName('any-option')
              // }
            }
            let next_disabled = topic_data[current_question].value && topic_data[current_question].value.length !== 0 ? false : true
            try {
              if (recommendations[topic_data[recommended_column_num]['value']][topic_data[current_question].column]) {
                next_disabled = false
              }
            } catch (e) {
              this.goBackToQuestionX(recommended_column_num)
              toast.error(
                <div>
                  <div>
                    Oops you found a bug we are trying to fix!
                  </div>
                  <div className="pt-sm">
                    This question was missed. Please fill it out and continue with the questions.
                  </div>
                  <div className="pt-sm">
                    Thank you for your patience.
                  </div>
                </div>
              , {autoClose: 10000})
            }
            this.setState({
              current_selections: current_selections,
              topic_data: topic_data,
              next_disabled: next_disabled
            })
          }
        } else if (value || value === 0) {
          const current_selections = topic_data[current_question].value || []
          for (const index in value) {
            const option = document.getElementById(String(value[index]))
            if (option) {
              option.classList.add('selected')
            } else {
              // logInfo("no options: " + value + " " + index)
            }
          }
          this.setState({ current_selections: current_selections })
        }
      }
    } catch (err) {
      // logError(err, "makeSelectionMultiple")
    }

  }

  makeSelection (e, value) {
    if (e) {
      if(!(e.target.hasAttribute('data-tip') || e.target.hasAttribute('fill-rule'))){
        var selected_option = document.getElementsByClassName('selected')
        while(selected_option.length > 0){
          selected_option[0].classList.remove('selected')
          selected_option = document.getElementsByClassName('selected')
        }
        e.currentTarget.classList.add('selected')
        this.setState({ next_disabled: false, current_selection: value })
      }
    } else if (value || value === false) {
      const option = document.getElementById(String(value))
      option.classList.add('selected')
      this.setState({ next_disabled: false, current_selection: value })
    }
  }

  showSaveResultsModal (title_text, description_text) {
    this.setState({
      show_save_results_modal: true,
      title_text: title_text,
      description_text: description_text
    })
  }

  setWidgetValues = (size_list, width_list, input_values, select_buttons=null, user_info_save=null) => {
    if (this.props.logged_in === 'true' && user_info_save) {
      try {
        if (window.localStorage.getItem('calculator_values')) {
          services.post_v2(APIURL.UPDATE_USER_INFO, {
            calculator_values: JSON.stringify(input_values)
          })
        }
      } catch (e) {
        logError(e, "window.localStorage.getItem")
      }
    }
    if (this.props.logged_in === 'false') {
      try {
        let calculator_values = window.localStorage.getItem('calculator_values')
        if (calculator_values) {
          calculator_values = JSON.parse(calculator_values)
          input_values = {...calculator_values, ...input_values}
        }
        window.localStorage.setItem('calculator_values', JSON.stringify(input_values))
      } catch (e) {
        logError(e, "window.localStorage.setItem")
      }
    }
    this.makeSelectionMultiple(null, null, size_list, width_list, input_values, select_buttons)
  }

  nextQuestion = async (skip_to_results=false) => {
    // console.log('nextQuestion')
    try {
      const {
        current_selection,
        current_selections,
        topic_data,
        current_question,
        secondary_selections,
        input_values,
        data_uuid,
        options_data,
        recommended_user_type,
        recommended_column_num,
        product_slug,
        number_of_questions,
      } = this.state
      let temp_current_question = current_question
      const completed = (current_question + 1 === number_of_questions)
      if (skip_to_results) {
        temp_current_question = number_of_questions - 1
        this.setState({mounting:true})
        window.scrollTo(0, 0)
      }
      if (current_question === 0) {
        this.setState({next_disabled: true})
        const fingerprint = await this.getFingerPrint()
        const result = await services.post_v2(APIURL.QUESTION_DATA,
          {
            'topic_slug': this.state.product_slug,
            'user_fingerprint': fingerprint,
            'referrer': this.state.referrer,
            'source': this.state.source,
            'campaign': this.state.campaign
          })
        if (result.response.data_uuid) {
          try {
            if (typeof window !== "undefined") {
              window.localStorage.setItem('data_uuid', result.response.data_uuid)
            }
          } catch {
            toast(
              <div>
                <div>
                  There was an error attempting to use cookies on your browser. Without cookies refreshing or closing the page will cause you to lose your progress.
                </div>
                <div className="pt-sm">
                  We recommend using Chrome browser or turning off &#34;Private Browsing Mode&#34; on Safari.
                </div>
              </div>
            , {autoClose: 15000})
          }
          const topic_data = await this.clearQuestionValues()
          this.setState({
            'data_uuid': result.response.data_uuid,
            'topic_data': topic_data,
            'extras_added': true
          }, () => {
            this.props.router.push(this.state.path + this.state.product_slug+'/' + (current_question + 1) + '/')
          })
        }

        const width = window.innerWidth
        const isMobile = width <= 768
        const test = document.documentElement
        // if (isMobile) {
        //   if(test.requestFullscreen) {
        //     test.requestFullscreen()
        //   } else if(test.mozRequestFullscreen) {
        //     test.mozRequestFullscreen()
        //   } else if(test.webkitRequestFullscreen) {
        //     test.webkitRequestFullScreen();
        //   }
        // }
      } else {
        let recommendations = CONSTANTS.RECOMMENDED_SELECTIONS[product_slug]
        let save_values = current_selection
        let save_secondary_values = null
        let save_input_values = null
        if (['multiple_select', 'related_table_multiple'].indexOf(topic_data[current_question].question_type) > -1 ) {
          save_values = current_selections
          save_secondary_values = secondary_selections
          save_input_values = input_values
        }
        if (secondary_selections !== save_secondary_values) {
          save_secondary_values = secondary_selections
        }
        if (current_question === recommended_column_num && !current_selection) {
          save_values = recommended_user_type
        } else if (['multiple_select', 'related_table_multiple'].indexOf(topic_data[current_question].question_type) > -1 && !save_values) {
          save_values = recommendations[topic_data[recommended_column_num]['value']][topic_data[current_question].column]
        } else if (!save_values) {
          save_values = recommendations[topic_data[recommended_column_num]['value']][topic_data[current_question].column][0]
        }
        const old_values = topic_data[current_question].value
        const updated_topic_data = topic_data
        updated_topic_data[current_question]['value'] = save_values
        updated_topic_data[current_question]['secondary_values'] = save_secondary_values
        updated_topic_data[current_question]['input_values'] = save_input_values
        const updated_options_data = options_data
        updated_options_data[updated_topic_data[current_question].column] = {
          question_type: updated_topic_data[current_question].question_type,
          value: updated_topic_data[current_question].value,
          locked_in: updated_topic_data[current_question].locked_in,
          secondary_values: updated_topic_data[current_question].secondary_values,
          secondary_column: updated_topic_data[current_question].secondary_column,
          related_reverse: updated_topic_data[current_question].related_reverse,
          input_values: updated_topic_data[current_question].input_values,
        }

        if (completed || skip_to_results) {
          this.setState({mounting:true})
          window.scrollTo(0, 0)
          await services.put_v2(APIURL.QUESTION_DATA, {
            column: topic_data[current_question].column,
            value: save_values,
            secondary_column: topic_data[current_question].secondary_column,
            related_reverse: topic_data[current_question].related_reverse,
            secondary_values: save_secondary_values,
            input_values: save_input_values,
            data_uuid: data_uuid,
            question_number: current_question,
            unit: topic_data[current_question].unit,
            question_type: topic_data[current_question].question_type,
            shortened_title: topic_data[current_question].shortened_title,
            options_data: updated_options_data,
            completed: completed
          })
          await services.get_v2(
            APIURL.PRODUCTS, {
              params: {
                slug: this.state.product_slug,
                options_data: JSON.stringify(updated_options_data),
                data_uuid: data_uuid
              }
            }
          ).then((response)=> {
            this.setState({
              matching_products: response.response.queryset,
              exact_matches: response.response.exact
            })
          })
        } else {
          services.put_v2(APIURL.QUESTION_DATA, {
            column: topic_data[current_question].column,
            value: save_values,
            secondary_column: topic_data[current_question].secondary_column,
            related_reverse: topic_data[current_question].related_reverse,
            secondary_values: save_secondary_values,
            input_values: save_input_values,
            data_uuid: data_uuid,
            question_number: current_question,
            unit: topic_data[current_question].unit,
            question_type: topic_data[current_question].question_type,
            shortened_title: topic_data[current_question].shortened_title,
            options_data: updated_options_data,
            completed: completed
          })
        }
        if (current_question === 1) {
          this.setState({ user_type: save_values })
        }
        // const toastMessage = getToastMessage(product_slug, old_values, save_values, current_question)
        // if (toastMessage) {
        //   toast.dismiss()
        //   toast.info(
        //     toastMessage,
        //     {
        //       position: "top-right",
        //       autoClose: 15000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //   })
        // }
        this.setState({
          topic_data: updated_topic_data,
          current_selection: null,
          current_selections: null,
          secondary_selections: null,
          input_values: null,
          options_data: updated_options_data,
        }, async () => {
          if (completed || skip_to_results) {
            if (this.props.logged_in === 'true') {
              this.saveResults()
            }
            this.props.router.push(this.state.path + this.state.product_slug+'/' + (temp_current_question + 1) + '/?results=' + data_uuid).then(()=> {
              this.setState({
                mounting:false,
                results_page: true,
                results_url: process.env.NEXT_PUBLIC_DOMAIN + this.state.path + this.state.product_slug+'/' + (temp_current_question + 1) + '/?results=' + data_uuid
              })
            })
          } else {
            this.props.router.push(this.state.path + this.state.product_slug+'/' + (temp_current_question + 1) + '/')
          }
          if (typeof window !== "undefined") {
          }
        })
      }
    } catch (e) {
      logError(e, "nextQuestion")
    }
  }

  buildQuestions = async (prevData = null) => {
    try {
      const questions_data = await services.get_v2(APIURL.TOPIC_QUESTIONS, { params: {slug: this.state.product_slug }})
      if (questions_data.statusCode && questions_data.statusCode === 404) {
        this.setState({mounting: false, error_message: questions_data.message})
      }
      const topic_status = questions_data.status
      let related_topics = questions_data.related_topics
      let group_topics = questions_data.group_topics
      let topic_name = questions_data.name
      let topic_name_plural = questions_data.name_plural
      let slider_data = questions_data.slider_data
      const TOPIC_DATA = {}
      const options_data = {}
      const question_data_for_remaining = {}
      const recommended_column = questions_data.recommended_column
      const related_button_container = []
      const group_topic_container = []
      const related_topic_buttons = []
      const group_topic_buttons = []
      if (related_topics) {
        for (let l in related_topics) {
          let t = related_topics[l]
          related_topic_buttons.push(
            <div key={l+'related_topics'} onClick={() => {
              this.setState(
                {close_sidebar: true},
                () =>{
                  this.props.router.push(this.state.path + t.slug+'/0/')
                }
              )
            }}>
              <a>
                <div className="product-option inverse">
                  {t.name}
                </div>
              </a>
            </div>
          )
        }
        if (related_topic_buttons.length > 0) {
          related_button_container.push(<div key='btm-nav-description' className='p-sm'>
            <div className="psg-container ">
              <div className="related-products blue">
                Commonly Paired Products
              </div>
              <div className="products-flex">
                <div className="product-container">
                  {related_topic_buttons}
                </div>
              </div>
            </div>
          </div>)
        }
      }
      if (group_topics) {
        for (let g in group_topics) {
          let group = group_topics[g]
          group_topic_buttons.push(<div key={"group-topics"+g} className="product-category mt-md">{group.name}</div>)
          for (let link in group.links) {
            let topic = group.links[link]
            // let slug = topic.slug
            // let name = topic.name
            group_topic_buttons.push(
              <div className={'mt-sm'} key={g+link+'group_topics'} onClick={() => {
                this.setState(
                  {close_sidebar: true},
                  () =>{
                    this.props.router.push(this.state.path + topic.slug+'/0')
                  }
                )
              }}>
                <a>
                  <div className="product-option inverse">
                    {topic.name}
                  </div>
                </a>
              </div>
            )
          }
        }
        if (group_topic_buttons.length > 0) {
          group_topic_container.push(<div key='btm-nav-description' className='p-sm'>
            <div className="psg-container ">
              <div className="related-products blue">
                More Product Categories
              </div>
              <div className="products-flex mt-none">
                <div className="product-container mt-none">
                  {group_topic_buttons}
                </div>
              </div>
            </div>
          </div>)
        }
      }

      let recommended_column_num = 1
      for (const [index, [key, value]] of Object.entries(Object.entries(questions_data.questions))) {
        question_data_for_remaining[value.related_column] = value
        if (value.related_column === recommended_column) {
          recommended_column_num = parseInt(index) + 1
        }
        //fuckery to keep "info intro icon" from wrapping away from last word of value.question
        const questionIntoArray = value.question.split(' ')
        const lastWord = questionIntoArray[questionIntoArray.length-1]
        const firstWords = questionIntoArray.slice(0, questionIntoArray.length - 1).join(' ')
        //end fuckery: now I can join the last word, with the Icon
        const title = (
          <div className="q-title">
            <span dangerouslySetInnerHTML={{ __html: firstWords }}/>
            <span className='noWrap'>
              &nbsp;{lastWord}&nbsp;
              {value.sub_text && (
                <BsInfoCircle
                  id='InfoIntro'
                  className={'clickable info-button'}
                  onClick={() => {
                    this.setState({
                      description_toggle_bool : !this.state.description_toggle_bool,
                    })
                  }}
                />
              )}
            </span>
          </div>
        )
        // if (value.related_column === recommended_column) {
        //   recommended_column_num = parseInt(index) + 1
        // }
        TOPIC_DATA[parseInt(index) + 1] = {
          column: value.related_column,
          question_title: title,
          top_image: value.top_image,
          options: value.options,
          // icon: <FiUsers className="fa-5x"/>,
          question_type: value.question_type,
          value: prevData && prevData[index] ? prevData[index].value : null,
          sub_text: value.sub_text,
          shortened_title: value.shortened_title,
          unit: value.unit,
          locked_in: value.locked_in,
          show_more: value.show_more,
          option_image_height: value.option_image_height,
          option_image_width: value.option_image_width,
          question_image_height: value.question_image_height,
          question_image_width: value.question_image_width,
          widget: value.widget,
          secondary_column: value.secondary_column,
          related_reverse: value.related_reverse,
          secondary_values: prevData && prevData[index] ? prevData[index].secondary_value : null,
          input_values: prevData && prevData[index] ? prevData[index].input_values : null,
        }
        options_data[value.related_column] = {
          question_type: value.question_type,
          value: prevData && prevData[index] ? prevData[index].value : null,
          secondary_column: value.secondary_column,
          related_reverse: value.related_reverse,
          secondary_values: prevData && prevData[index] ? prevData[index].secondary_value : null,
          input_values: prevData && prevData[index] ? prevData[index].input_values : null,
          locked_in: prevData && prevData[locked_in] ? prevData[index].locked_in : null,
        }
      }
      this.setState({
        options_data: options_data,
        // questions: QUESTIONS,
        topic_data: TOPIC_DATA,
        questions_data: question_data_for_remaining,
        number_of_questions: Object.keys(TOPIC_DATA).length + 1,
        recommended_column: recommended_column,
        recommended_column_num: recommended_column_num,
        topic_status: topic_status,
        related_button_container: related_button_container,
        group_topic_container: group_topic_container,
        topic_name: topic_name,
        topic_name_plural: topic_name_plural || topic_name + 's',
        slider_data: slider_data
      })
      // console.log(TOPIC_DATA)
      return {
        recommended_column: recommended_column,
        recommended_column_num: recommended_column_num
      }
    } catch (e) {
      logError(e, "buildQuestions")
    }
  }

  startProductPicker = async (didMount=false) => {

    try {
      // console.log('startProductPicker')
      const {recommended_column, recommended_column_num} = await this.buildQuestions()
      let topic_data = {}
      let data_uuid = this.props.router.query.results
      let results_page = false
      let has_seen_results = false
      // let has_value = false
      let current_selection = null
      let current_selections = null
      let matching_products = null
      let results_url = null
      let exact_matches = true
      let recommended_user_type = null
      let alert_ids = []
      if (recommended_column){
        recommended_user_type = CONSTANTS.RECOMMENDED_SELECTIONS[this.state.product_slug][recommended_column][0]
      }
      if (this.props.logged_in === 'true') {
        const result = await services.get_v2(APIURL.PRICE_DROP_ALERTS, {params: {}})
        if (result.status === "success") {
          alert_ids = result.response.alert_ids
        }
      }
      if (recommended_column && !recommended_user_type && this.state.product_slug !== 'mountain-bike') {
        logError('No recommended_user_type', recommended_user_type)
      }
      let referrer = ''
      let source = ''
      let campaign = ''
      if (document) {
        referrer = document.referrer
      }

      if (this.props && this.props.router && this.props.router.query) {
        if (this.props.router.query.src) {
          source = this.props.router.query.src
        }
        if (this.props.router.query.c) {
          campaign = this.props.router.query.c
        }
      }
      if (campaign === '' && source === '') {
        try {
          if (typeof window !== "undefined") {
            source = window.localStorage.getItem('source')
            campaign = window.localStorage.getItem('campaign')
          }
        } catch (e) {
          logError(e, "window.localStorage.getItem")
        }

      }

      if (data_uuid) {
        try {
          if (typeof window !== "undefined") {
            window.localStorage.setItem('data_uuid', data_uuid)
          }
        } catch {
          toast(
            <div>
              <div>
                There was an error attempting to use cookies on your browser. This may cause SimplyBuy to not work properly.
              </div>
              <div className="pt-sm">
                We recommend using Chrome browser or turning off &#34;Private Browsing Mode&#34; on Safari.
              </div>
            </div>
          , {autoClose: 15000})
        }
        results_page = true
        has_seen_results = true
      } else {
        try {
          if (typeof window !== "undefined") {
            data_uuid = window.localStorage.getItem('data_uuid')
          }
        } catch (e) {
          logError(e, "window.localStorage.getItem")
        }
      }
      let current_question = parseInt(this.props.router.query.question)
      if (data_uuid && current_question !== 0) {
        const question_data = await services.get_v2(APIURL.QUESTION_DATA, {
          params: {
            data_uuid: data_uuid,
            topic_slug: this.state.product_slug
          }
        })
        if (question_data.statusCode === 404) {
          this.setState({
            mounting: false,
            recommended_user_type: recommended_user_type,
            referrer: referrer,
            source: source,
            campaign: campaign,
            has_seen_results: false,
            current_question: 0
          }, () => {
              this.props.router.push(this.state.path + this.state.product_slug+'/0/')
            }
          )
        } else {
          topic_data = await this.setQuestionValues(question_data)
          current_question = parseInt(this.props.router.query.question)
          if (topic_data[current_question] && ['multiple_select', 'related_table_multiple'].indexOf(topic_data[current_question].question_type) > -1) {
            current_selections = topic_data[current_question].value
          } else if (topic_data[current_question]) {
            current_selection = topic_data[current_question].value
          }
          if (data_uuid && current_question - 1 === Object.keys(topic_data).length) {
            const options_data = {}
            for (const i in topic_data) {
              options_data[topic_data[i].column] = {
                question_type: topic_data[i].question_type,
                value: topic_data[i].value,
                locked_in: topic_data[i].locked_in,
                secondary_values: topic_data[i].secondary_values,
                secondary_column: topic_data[i].secondary_column,
                related_reverse: topic_data[i].related_reverse,
                input_values: topic_data[i].input_values,
              }
            }
            const response = await services.get_v2(APIURL.PRODUCTS, {
               params: {
                 slug: this.state.product_slug,
                 options_data: JSON.stringify(options_data),
                 data_uuid: data_uuid
               }
            })
            matching_products = response.response.queryset
            exact_matches = response.response.exact
          }
          results_url = process.env.NEXT_PUBLIC_DOMAIN + this.state.path + this.state.product_slug+'/' + (current_question + 1) + '/?results=' + data_uuid
          const options_data = {}
          for (const i in this.state.topic_data) {
            options_data[this.state.topic_data[i].column] = {
              question_type: this.state.topic_data[i].question_type,
              value: this.state.topic_data[i].value,
              secondary_values: this.state.topic_data[i].secondary_values,
              locked_in: this.state.topic_data[i].locked_in,
              secondary_column: this.state.topic_data[i].secondary_column,
              related_reverse: this.state.topic_data[i].related_reverse,
              input_values: this.state.topic_data[i].input_values,
            }
          }
          this.setState({
            data_uuid: data_uuid,
            current_question: current_question,
            results_page: results_page,
            topic_data: topic_data,
            current_selection: current_selection,
            mounting: false,
            matching_products: matching_products,
            results_url: results_url,
            has_seen_results: has_seen_results,
            options_data: options_data,
            exact_matches: exact_matches,
            recommended_user_type: recommended_user_type,
            referrer: referrer,
            source: source,
            campaign: campaign,
            alert_ids: alert_ids
          }, () => {
            if (topic_data[current_question]) {
              if (['multiple_select', 'related_table_multiple'].indexOf(topic_data[current_question].question_type) > -1) {
                this.makeSelectionMultiple(null, current_selections)
              } else {
                this.makeSelection(null, current_selection)
              }
            }
          })
        }
      } else {
        this.setState({
          mounting: false,
          recommended_user_type: recommended_user_type,
          referrer: referrer,
          source: source,
          campaign: campaign,
          has_seen_results: false,
          current_question: 0
        }, () => {
            this.props.router.push(this.state.path + this.state.product_slug+'/0/')
          }
        )
      }
    } catch (e) {
      logError(e, "startProductPicker")
    }
  }

  componentDidMount = () => {
    if (this.fetchPromise) {
      return;
    }
    this.fetchPromise = this.startProductPicker()
  }

  getRemainingCount = (topic_data, current_question, options_data) => {
    try {
      if(current_question > 1) {
        var column_name = topic_data[current_question].column
        services.get_v2(
          APIURL.PRODUCT_COUNT, {
            params: {
              slug: this.state.product_slug,
              options_data: JSON.stringify(options_data),
              column_name: column_name,
              // question_data: this.state.questions_data[column_name],
              data_uuid: this.state.data_uuid
            }
          }
        ).then((response) => {
          if (response.response) {
            try {
              const count_dict = response.response.queryset
              let product_count = 0
              const value_dict = []
              for (const count in count_dict) {
                let dict_column = column_name
                const question_type = topic_data[current_question]['question_type']
                if (['related_table', 'related_table_multiple'].indexOf(question_type) > -1) {
                  if (topic_data[current_question]['related_reverse']) {
                    dict_column = topic_data[current_question]['secondary_column'] + '__' + dict_column
                  } else {
                    dict_column = dict_column + '__' + dict_column
                  }
                }
                const option = document.getElementById('option-count-'+count_dict[count][dict_column])
                if (option) {
                  // IF FIRST INSTANCE OF SHOWING NUMBER REMAINING
                  if (parseInt(count) === 0 && current_question===2) {
                    option.parentNode.classList.add('remaining-with-tooltip')
                    option.innerHTML = count_dict[count]['count']
                    product_count = product_count + parseInt(count_dict[count]['count'])
                    value_dict.push(String(count_dict[count][dict_column]))
                  } else {
                    option.innerHTML = count_dict[count]['count']
                    product_count = product_count + parseInt(count_dict[count]['count'])
                    value_dict.push(String(count_dict[count][dict_column]))
                  }
                }
              }
              let question_options = this.state.questions_data[column_name].options
              for (const option_data in question_options) {
                let option_value = question_options[option_data].value
                if (option_value === 'True') {
                  option_value = 'true'
                } else if (option_value === 'False') {
                  option_value = 'false'
                }
                const option_element = document.getElementById('option-count-'+option_value)
                if (value_dict.indexOf(String(option_value)) === -1) {
                  if (option_value === '0') {
                    option_element.innerHTML = product_count.toString()
                  } else {
                    if (option_element) {
                      option_element.innerHTML = '0'
                    }
                    // option_element.parentNode.parentNode.classList.add('disabled-option')
                  }
                }
              }
            } catch (err) {
              logError(err)
            }
          } else {
            // logInfo("getRemainingCount2")
          }
        })
      }
    } catch (err) {
      logError(err)
    }
  }

  componentDidUpdate = async () => {
    try {
      if (this.props && this.props.router && this.props.router.query) {
        if (this.props.router.query.slug !== this.state.product_slug && this.state.product_slug) {
          this.setState({mounting: true, product_slug: this.props.router.query.slug}, () =>{
            this.startProductPicker()
          })
        }
      }
      const { extras_added, current_question, results_page, topic_data, mounting, number_of_questions } = this.state
      if (!mounting) {
        const url_q_num = parseInt(this.props.router.query.question)
        // let disable_next = true
        if (url_q_num !== current_question) {
          // if (
          //   (topic_data[url_q_num] && (topic_data[url_q_num].value ||
          //   topic_data[url_q_num].value === false))) {
          // }
          const results_page = current_question === number_of_questions
          if (results_page) {
            this.setState({
              has_seen_results: true,
            })
          }
          this.setState({
            current_question: url_q_num,
            results_page: results_page,
          }, () => {
            if (topic_data[url_q_num]) {
              if (['multiple_select', 'related_table_multiple'].indexOf(topic_data[url_q_num].question_type) > -1) {
                this.makeSelectionMultiple(null, topic_data[url_q_num].value)
              } else {
                this.makeSelection(null, topic_data[url_q_num].value)
              }
              this.add_recommendations(this.state.current_question)
              this.getRemainingCount(this.state.topic_data, this.state.current_question, this.state.options_data)
            }
            if (this.state.results_page === true) {
              this.setState({has_seen_results: true})
            }
          })
        } else if (results_page !== (current_question === number_of_questions)) {
          if (current_question === number_of_questions) {
            if (!this.state.matching_products) {
              this.setState({mounting: true})
              this.startProductPicker()
            }
            else {
              // if (this.state.has_seen_results === false && this.props.logged_in === false) {
              //   setTimeout(() => {
              //     this.showSaveResultsModal(
              //       "Save Your Results",
              //       "Register now to have all your SimplyBuy results saved in one place!"
              //     ) }, 8000)
              // }
              this.setState({
                results_page: current_question === number_of_questions,
                has_seen_results: current_question === number_of_questions
              })
            }
          } else {
            this.add_recommendations(this.state.current_question)
            this.setState({
              results_page: current_question === number_of_questions,
            })
          }
        } else {
          if(!this.state.results_page &&
             extras_added === false &&
             current_question !== 0) {
            let has_seen_results = true
            for (const question in this.state.topic_data) {
              if (!this.state.topic_data[question].value) {
                has_seen_results = false
              }
            }
            this.setState({extras_added: true, has_seen_results: has_seen_results}, () => {
              this.add_recommendations(this.state.current_question)
              this.getRemainingCount(this.state.topic_data, this.state.current_question, this.state.options_data)
            })
          }
        }
      }
    } catch (e) {
      logError(e, "componentDidUpdate")
    }
  }

  setQuestionValues = async (question_data) => {
    try {
      question_data = JSON.parse(question_data.question_data)
      const topic_data = this.state.topic_data
      for (const question in topic_data) {
        if (question_data && question_data[topic_data[question].column] && question_data[topic_data[question].column].value) {
          topic_data[question].value = question_data[topic_data[question].column].value
        }
        if (question_data && question_data[topic_data[question].column] && question_data[topic_data[question].column].secondary_values) {
          topic_data[question].secondary_values = question_data[topic_data[question].column].secondary_values
        }
        if (question_data && question_data[topic_data[question].column] && question_data[topic_data[question].column].input_values) {
          topic_data[question].input_values = question_data[topic_data[question].column].input_values
        }
        if (question_data && question_data[topic_data[question].column]) {
          if (question_data[topic_data[question].column].locked_in === false) {
            topic_data[question].locked_in = false
          } else if (question_data[topic_data[question].column].locked_in === true) {
            topic_data[question].locked_in = true
          }
        }
      }
      return topic_data
    } catch (e) {
      return this.state.topic_data
    }
  }

  clearQuestionValues = async () => {
    const topic_data = this.state.topic_data
    for (const q in topic_data) {
      topic_data[q].value = null
      topic_data[q].secondary_values = null
      topic_data[q].input_values = null
    }
    return topic_data
  }

  progressClick = async (question) => {
    if (this.state.topic_data[question].value || this.state.topic_data[question].value === false) {
      this.setState({close_sidebar: true})
      this.props.router.push(this.state.path + this.state.product_slug+'/' + question + '/')
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0)
      }
    } else if (parseInt(question) === 1 || this.state.topic_data[question-1].value || this.state.topic_data[question-1].value === false) {
      this.setState({close_sidebar: true})
      this.props.router.push(this.state.path + this.state.product_slug+'/' + question + '/')
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0)
      }
    }
  }

  lockClick = async (question_number, locked_value) => {
    const td = this.state.topic_data
    const od = this.state.options_data
    const data_uuid = this.state.data_uuid
    od[td[question_number].column].locked_in = !locked_value
    td[question_number].locked_in=!locked_value
    this.setState({topic_data: td, options_data: od, mounting: true})

    let column = td[question_number].shortened_title
    let value = td[question_number].shortened_title
    if (!locked_value) {
      toast(
        <div>
          <div className="title-toast">
            {column + ' Locked!'}
          </div>
          <div>
            {'Results will have ' + column +' with your selected filter value.'}
          </div>
        </div>
      )
    } else {
      toast(
        <div className="title-toast">
            {column + ' Unlocked!'}
        </div>
      )
    }

    services.put_v2(APIURL.CHANGE_LOCKED_VALUE, {
      data_uuid: data_uuid,
      column: td[question_number].column,
      locked_value: !locked_value,
    }).then(() => {
      services.get_v2(
        APIURL.PRODUCTS, {
          params: {
            slug: this.state.product_slug,
            options_data: JSON.stringify(od),
            data_uuid: data_uuid,
          }
        }
      ).then((response)=> {
        this.setState({
          matching_products: response.response.queryset,
          exact_matches: response.response.exact,
          mounting: false
        })
      })
    })

  }

  setCloseFalse = async () => {
    this.setState({close_sidebar: false})
  }

  hideSaveResultsModal = () => {
    this.setState({ show_save_results_modal: false })
  }

  makeCopy = async () => {
    const copy = await services.post_v2(APIURL.MAKE_COPY, {data_uuid: this.state.data_uuid})
    if (copy.response.data_uuid) {
      this.props.router.push(this.state.path + this.state.product_slug+'/' + this.state.current_question + '/?results=' + copy.response.data_uuid)
      toast('You are now on the copy!')
    }
  }

  addLinkClick (product_id, source) {
    services.post_v2(APIURL.ADD_LINK_CLICK, {data_uuid: this.state.data_uuid, product_id: product_id, source: source})
  }

  startFresh = async () => {
    this.setState({mounting: true}, () => {
      this.props.router.push(this.state.path + this.state.product_slug+'/0/')
      this.startProductPicker()
    })
  }

  saveResults = async () => {
    const saved_results = await services.post_v2(APIURL.SAVE_RESULTS, {data_uuid: this.state.data_uuid})
    if (saved_results.response.data_uuid !== this.state.data_uuid) {
      this.props.router.push(this.state.path + this.state.product_slug+'/' + this.state.current_question + '/?results=' + saved_results.response.data_uuid)
      toast('Results Saved!')
    }
  }

  // toggleSwitch = () => {
  //   this.setState({ 'show_constants': !this.state.show_constants})
  // }

  goToSite (link, product_id, data_uuid, source, addLinkClick=true) {
    if (link.includes('avantlink')) {
      link = link + '&ctc=' + data_uuid
    }
    if (addLinkClick) {
      this.addLinkClick(product_id, source)
    }
    if (typeof window !== "undefined") {
      if (link.includes('amazon.com')) {
        window.open(link, '_blank', 'noopener')
      } else {
        window.open(link, '_blank', 'noopener,noreferrer')
      }
    }
  }

  sortByHighEndScore = (object1, object2) => {
    const obj1 = object1['top_pick_score_all']['score']
    const obj2 = object2['top_pick_score_all']['score']

    const obj1price = object1['exact_price']
    const obj2price = object2['exact_price']

    if (obj1 > obj2) {
      return -1
    }
    if (obj1 < obj2) {
      return 1
    }
    if (obj1 === obj2) {
      if (obj1price < obj2price) {
        return -1
      }
      if (obj1price > obj2price) {
        return 1
      }
    }
    return 0
  }

  calculateValueScore = (highEndScore, price, maxHighEndScore, maxPrice, minHighEndScore, minPrice) => {
    const highEndWeight = 0.7
    const priceWeight = 0.3
    const highEndNormalized = (highEndScore - minHighEndScore) / (maxHighEndScore - minHighEndScore)
    const priceNormalized = (price - minPrice) / (maxPrice - minPrice);
    const valueScore = (highEndWeight * highEndNormalized) + (priceWeight * (1 - priceNormalized));
    return valueScore
}

  sortPicks = (matching_products, slug) => {
    // console.log(matching_products)
    // Remove duplicates for closest matches
    // Ugly code should be fixed on backend
    let temp_products = []
    let ids = []
    // if (!this.state.exact_matches) {
    for (let i in matching_products) {
      if (ids.indexOf(matching_products[i].id) === -1) {
        ids.push(matching_products[i].id)
        temp_products.push(matching_products[i])
      }
    }
    matching_products = temp_products
    // }
    let value_score = 0
    let high_end_score = 0
    let high_end_product = null
    let high_end_price = 0
    let median_score = 0
    let median_price = 0
    let high_end_products_specs = []
    let value_products_specs = []
    let value_price = 0
    let top_value_price = 0
    let extras = []
    for (const p in matching_products) {
      let product = matching_products[p]
      let product_score = product['top_pick_score_all']['score']
      if (p === '0' || (product_score > high_end_score)) {
        high_end_product = product
        high_end_score = product['top_pick_score_all']['score']
        high_end_price = product['exact_price']
      }

      if (value_price === 0 || (product_score > value_score && product['exact_price'] <= value_price)) {
        if (matching_products[p]['top_pick_score_all']['score'] < 0) {
          extras.push(matching_products[p])
        } else {
          value_score = matching_products[p]['top_pick_score_all']['score']
          value_price = matching_products[p]['exact_price']
        }
      }

    }
    median_score = (value_score + high_end_score) / 2.0
    median_price = (value_price + high_end_price) / 2.0
    let score_vs_price_list = []
    let score_vs_price_to_beat = 0
    for (const p in matching_products) {
      let product = matching_products[p]
      if (slug === 'tv') {
        if (high_end_score === value_score && value_price === high_end_price) {
          if (product['top_pick_score_all']['score'] === high_end_score) {
            score_vs_price_list.push(product)
          }
        } else {
          let current_score_vs_price = this.calculateValueScore(
            product['top_pick_score_all']['score'],
            product['exact_price'],
            high_end_score,
            high_end_price,
            value_score,
            value_price
          )
          if (current_score_vs_price >= score_vs_price_to_beat) {
            if (score_vs_price_list.length >= 2 && product['exact_price'] <= score_vs_price_list[score_vs_price_list.length - 1]['exact_price'] * 1.02 ) {
              let x = score_vs_price_list.pop()
            }
            score_vs_price_list.push(product)
            score_vs_price_to_beat = current_score_vs_price
          }
        }
      } else {
        let product_price = product['exact_price']
        let product_score = product['top_pick_score_all']['score']
        if(product_score >= value_score && product_score <= median_score && (high_end_product && product_price < median_price)) {
          value_products_specs.push(matching_products[p])
        } else if(product_score >= median_score && product_price <= median_price) {
          value_products_specs.push(matching_products[p])
        } else if (product_score >= median_score ) {
          high_end_products_specs.push(matching_products[p])
        }
      }
    }
    if (slug === 'tv') {
      let mid = Math.ceil(score_vs_price_list.length/2)
      value_products_specs = score_vs_price_list.slice(0, mid)
      high_end_products_specs = score_vs_price_list.slice(mid)
    }
    high_end_products_specs.sort((product1, product2) => {
      return this.sortByHighEndScore(product1, product2)
    })
    if (value_products_specs.length < 3) {
      for (let p in extras) {
        value_products_specs.push(extras[p])
      }
    }
    const products = {'highend_picks': high_end_products_specs, 'value_picks': value_products_specs}
    // console.log(products)
    return products
  }

  highlightPopular = () => {
    const { current_selection, current_selections } = this.state
    if (!current_selection && (!Array.isArray(current_selections) || !current_selections.length)) {
      let selected_options = document.getElementsByClassName('recommended')
      for (let x in selected_options) {
        if(selected_options[x] && selected_options[x].classList) {
          selected_options[x].classList.add('will-add')
        }
      }
      selected_options = document.getElementsByClassName('recommended-with-tooltip')
      for (let x in selected_options) {
        if(selected_options[x] && selected_options[x].classList) {
          selected_options[x].classList.add('will-add')
        }
      }
    }
  }

  removeHighlight = () => {
    let selected_options = document.getElementsByClassName('recommended')
    for (let x in selected_options) {
      if(selected_options[x] && selected_options[x].classList) {
        selected_options[x].classList.remove('will-add')
      }
    }
    selected_options = document.getElementsByClassName('recommended-with-tooltip')
    for (let x in selected_options) {
      if (selected_options[x] && selected_options[x].classList) {
        selected_options[x].classList.remove('will-add')
      }
    }
  }

  toggleShowTipsOn = () => {
    this.setState({show_tips: true}, () => {
      this.end_product_tips.scrollIntoView({ behavior: "smooth" });
    })
  }

  toggleShowTipsOff = () => {
    this.setState({show_tips: false}, () => {
      // this.end_product_tips.scrollIntoView({ behavior: "smooth" });
    })
  }

  createFilters = (topic_data, related_button_container, group_topic_container) => {
    let progress = []
    let selections = []
    let constant_columns = []
    let hide_locks = true

    if (this.state.results_page && !this.state.exact_matches) {
      hide_locks = false
    }

    for (const question in topic_data) {
      let display_none = false
      if (topic_data[question].value) {
        const values = []
        var constant = false
        if (['multiple_select', 'related_table_multiple'].indexOf(topic_data[question].question_type) > -1) {
          if (topic_data[question].value.length === 1 && topic_data[question].value[0] !== '0' && topic_data[question].question_type !== 'related_table_multiple') {
            // UNCOMMENT CONSTANT_COLUMNS
            // constant = true
            // constant_columns.push(this.state.topic_data[question].column)
          } else if (topic_data[question].value.length === 1 && topic_data[question].value[0] === '0') {
            display_none = true
          }
          values.push(
            <div className="selection-values" key={question + '-unit'}>
              {add_unit(topic_data[question].value.join(', '), topic_data[question].unit, false)}
            </div>
          )
        } else {
          if (this.state.topic_data[question].value !== 0 && topic_data[question].value !== '0' && !['related_table', 'or_greater', 'or_less'].includes(topic_data[question].question_type)) {
            // UNCOMMENT CONSTANT_COLUMNS
            // constant = true
            // constant_columns.push(topic_data[question].column)
          }
          values.push(
            <div className="selection-values" key={question + '-unit'}>
              {add_unit(topic_data[question].value, topic_data[question].unit, false)}
            </div>
          )
        }

        if (topic_data[question].value === '0' || topic_data[question].value === ['0']) {
          display_none = true
        }

        selections.push(
          <div className="selection-row" key={'section-row-'+question}>
            <div className={"lock-button-container " + classNames({'display-none': hide_locks})}>
              <GoLock className={"lock-button " + classNames({locked: topic_data[question].locked_in, 'display-none': display_none})} onClick={()=>{this.lockClick(question, topic_data[question].locked_in)}}/>
            </div>

            <div key={question + '-link'} onClick={() => { this.progressClick(question) }} className={"pointer selection-row-flex " + classNames({'constant': constant === true})}>
              <div className="selection-numbers">{question}</div>
              <div className="filter">
              <div className="selection-labels">{topic_data[question].shortened_title}:</div>
              <div>{values}</div>
            </div>
          </div>
          </div>
        )
      }
      progress.push(
        <li
          onClick={() => { this.progressClick(question) }}
          className={ classNames({ 'active-current': this.state.current_question === parseInt(question), 'active': this.state.topic_data[question].value || this.state.topic_data[question].value === false })}
          key={question + '-progress'}
        >
        </li>)
    }
    if (this.state.has_seen_results && related_button_container.length > 0) {
      selections.push(
        <div
          className="hidden-sm-down related-products-black"
          key={'related-button-container1'}>
            {related_button_container}
        </div>
      )
    }
    if (this.state.has_seen_results && group_topic_container.length > 0) {
      selections.push(
        <div
          className="hidden-sm-down related-products-black"
          key={'related-button-container2'}>
            {group_topic_container}
        </div>
      )
    }
    // UNCOMMENT CONSTANT_COLUMNS
    {/*if (selections.length > 0 && constant_columns.length > 0) {
      selections.push(
        <div key={'legend'} className="legend desktop">
          <div className="color-key"></div>
          <span>Exact Specs</span>
          {this.state.results_page && this.state.exact_matches && (
            <Switch containerClasses={"switch-container mt-sm"} label={'Show exact specs?'} checked={this.state.show_constants} switchChange={this.toggleSwitch}/>
          )}
        </div>
      )
    }*/}
    return {progress: progress, selections: selections, constant_columns: constant_columns }
  }

  pushDefaultSpecs = (product) => {
    const productDetails = []
    productDetails.push(
      <div key={'row3'} className="flex-row alternate-color">
        <div className="label">Price</div>
        <div className="value">${product['exact_price'].toFixed(2)}</div>
      </div>
    )
    return productDetails
  }

  excludeProduct = async (product_id) => {
    this.setState({mounting: true})
    const response = await services.post_v2(APIURL.ADD_EXCLUDED_PRODUCT,
      {
        'data_uuid': this.state.data_uuid,
        'product_id': product_id,
        'slug': this.state.product_slug,
        'options_data': JSON.stringify(this.state.options_data),
      })
      const matching_products = response.response.queryset
      const exact_matches = response.response.exact
    this.setState({
      mounting: false,
      matching_products: matching_products,
      exact_matches: exact_matches,
    })

  }

  getAlertsClick = async (product_id, exact_price, product_name, active_alert) => {
    if (this.props.logged_in === "true") {
      const result = await services.post_v2(APIURL.PRICE_DROP_ALERTS,
        {
          product_id: product_id,
          exact_price: exact_price
        })
      if (result.status === "success") {
        if (result.response.muted === true) {
          toast(<div>
            <div className="toast-product-name">{product_name}</div>
            <div>Email Alerts Turned Off!</div>
          </div>)
        } else {
          toast(
            <div>
              <div className="toast-product-name">{product_name}</div>
              <div>Email Alerts Turned On!</div>
            </div>
          )
        }
        this.setState({'alert_ids': result.response.alert_ids})
      } else if (result.status === "IntegrityError") {
        toast('You already have email alerts for "' + product_name + '"')
      } else {
        toast('An error occured.')
      }
    } else {
      this.showSaveResultsModal(
        "Price Drops Alerts",
        "Sign up to get email alerts for price drops on this product. It's free and signing up unlocks other cool features like saving your results!"
      )
    }
  }

  createDisplayProduct = (product, product_details, product_type) => {
    const { alert_ids } = this.state
    const active_alert = alert_ids.indexOf(product['id']) >= 0 ? true : false
    let altLink
    let links = []
    let priceMatch = null
    let freeShipping = null
    let returns = null
    let returnPolicyUrl = null
    let returnPolicyDays = null
    let ordersOverShipFree = null
    let shippingPolicyUrl = null
    let base = ''
    let multiple = false

    let hidePrice = false
    // if (product['link'] && product['link'].includes('amazon.com')) {
    //   hidePrice = true
    // }
    if (product['links']){
      // const chosenLink = this.chooseLink(product['links'])
      // base = chosenLink?.['base']
      const key1= Object.keys(product['links'])[0]
      // if (product['links'][key1]['link'].includes('amazon.com')) {
      //   hidePrice = true
      // }
    }
    if (product['link']) {
      if (product['link'].includes('amazon.com')) {
        links.push(
          <a
            key={'details-button-'+product['id']}
            className={"action-btn-gld continue-btn btn-m10 small see-details " + classNames({'blue': product_type === 'value'})}
            onClick= {() => {
              this.addLinkClick(product['id'], 'See Details')
            }}
            href={product['links'][key]['link']}
          >
            See Details
          </a>
        )
      } else {
        links.push(
          <button
            className={"action-btn-gld continue-btn btn-m10 small see-details " + classNames({'blue': product_type === 'value'})}
            onClick= {() => {
              this.goToSite(product['link'], product['id'], this.state.data_uuid)
            }}
            key={'button-link-'+product['id']}>
              See Details
          </button>
        )
      }
    } else if (product['links']) {
      const chosenLink = this.chooseLink(product['links'])
      altLink = chosenLink?.['link']
      base = chosenLink?.['base']
      shippingPolicyUrl = chosenLink?.['free_shipping_policy_url']
      ordersOverShipFree = chosenLink?.['orders_over_ship_free']
      returnPolicyDays = chosenLink?.['return_policy_days']
      returnPolicyUrl = chosenLink?.['return_policy_url']
      returns = returnPolicyUrl ? true : false
      freeShipping = product.exact_price > ordersOverShipFree ? true : false
      priceMatch = chosenLink?.['price_match_url']

      if (Object.keys(product['links']).length < 2) {
        const key = Object.keys(product['links'])[0]
        if (product['links'][key]['link'].includes('amazon.com')) {
          links.push(
            <a
              key={'details-button'+key}
              className={"action-btn-gld continue-btn btn-m10 small see-details " + classNames({'blue': product_type === 'value'})}
              onClick= {() => {
                this.addLinkClick(product['id'], 'See Details')
              }}
              href={product['links'][key]['link']}
            >
              {hidePrice ? 'See Price' : 'See Details'}
            </a>
          )
        } else {
          links.push(
            <button
              className={"action-btn-gld continue-btn btn-m10 small see-details " + classNames({'blue': product_type === 'value'})}
              onClick= {() => {
                this.goToSite(product['links'][key]['link'], product['id'], this.state.data_uuid)
              }}
              key={'button-link'+key}>
                {hidePrice ? 'See Price' : 'See Details'}
            </button>
          )
        }
      } else {
        for (const key in product['links']) {
          multiple = true
          links.push(
            <button
              key={'button1'+key}
              className={"action-btn-gld continue-btn btn-m10 small see-details no-min " + classNames({'blue': product_type === 'value'})}
              onClick= {() => {
                this.goToSite(product['links'][key]['link'], product['id'], this.state.data_uuid)
              }}>
                <span>{product['links'][key]['sizes'].join(", ")}</span>
            </button>
          )
        }
        links = [
          <div key={'links-container'} className="multiple-see-details">
            <div className="sd-text">See Details:</div>
            <div className="sd-buttons">{links}</div>
          </div>
        ]
      }
    }
    return (
      <div data-product-id={product.id}>
        <div className="product-top-container clickable"onClick={() => {this.goToSite(product['link'] ? product['link'] : altLink , product['id'], this.state.data_uuid)}}>
          <div className="product-img">
            {product['amazon_image_url'] && (

                <LazyImg
                  src={product['amazon_image_url']}
                  effect={'blur'}
                  classNames='r-img'
                />
            )}
            {product['trimmed_product_image'] && !product['amazon_image_url'] && (
              <Image
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: '100%', height: 'auto' }}
                src={product['trimmed_product_image']}
                alt={'Product Image'} />
            )}
            {!product['trimmed_product_image'] && !product['amazon_image_url'] && (
              <Image
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: '100%', height: 'auto' }}
                src={"/no-image.png"}
                alt={'No Image'} />
            )}
          </div>
          <div className="product-name">
            <div className="name">
              {product['name']}
            </div>
            <div className="by-brand">
              by <span className="brand">{product['brand']}</span>
            </div>
            {product.is_on_sale &&
              <div>
                <div className='original-price strike'>
                  ${product['original_price'].toFixed(2)}
                </div>
                <div>
                <span className='price sale alt-margins'>
                  ${product['exact_price'].toFixed(2)} Sale
                </span>
                </div>
              </div>
            }
            {!product.is_on_sale &&
              <div className={"price " + classNames({'display-none': hidePrice})}>
                ${product['exact_price'].toFixed(2)}
              </div>
            }
          </div>
        </div>
        {<div className={'actions '}>
          <div className="ta-right">
            <button className={"price-drop-btn"} onClick={
              () => {
                this.getAlertsClick(product['id'], product['exact_price'], product['name'], active_alert)
              }}>
              {(active_alert) ? "Remove Price Alerts" : "Get Price Drop Alerts"}
            </button>
          </div>
          <div className="action-btns-container">
            {links}
          </div>
        </div>}
        {/*<div className={'exclude-btn-container'}>
          <IoMdRemoveCircle data-tip data-for={'product-tt'} className={'clickable'} onClick={()=> {this.excludeProduct(product.id)}}/>
        </div>
        <ReactTooltip className='' id={'product-tt'} type='' effect='solid'>
          <div className="">Not interested in this product? Exclude it from these results.</div>
        </ReactTooltip>*/}
        <div className="product-details">
          <div className="slider-container">
            {product_details}
          </div>
        </div>
        <div className='feat-btn-container'>
          <div className='links-container'>
            {freeShipping && !product.radar &&
              <div className='policy-link' onClick={()=>{this.goToSite(base+shippingPolicyUrl, product.id, this.data_uuid, 'policy_link')}}>
                <GiCheckMark/> Free Shipping
              </div>
            }
            {priceMatch && !product.radar &&
              <div className='policy-link' onClick={()=>{this.goToSite(base+priceMatch, product.id, this.data_uuid, 'policy_link')}}>
                <GiCheckMark/> Price Match
              </div>
            }
          </div>
        </div>
      </div>

    )
  }

  chooseLink = (links) => {
      let arr = []
      for (let link in links){
        arr.push(links[link])
      }
      arr.sort((a, b) =>{
        return b.sizes.length - a.sizes.length
      })
      return arr[0]
  }

  makeProductDetails = (topic_data, product, constant_columns, is_value) => {
    let count = 0
    const productDetails = []
    for (const question in topic_data) {
      if (topic_data[question].column !== 'price_range' && topic_data[question].column !== 'user_type' && topic_data[question].column !== 'brand') {
        if (this.state.show_constants === true || !constant_columns.includes(topic_data[question].column)) {
          add_unit(product[topic_data[question].column], topic_data[question].unit, true)
          productDetails.push(
            <div key={'row'+question+STATIC_PRODUCT_ROWS} className={"flex-row " + classNames({'alternate-color': count % 2 === 1})}>
              <div className={"label "}>{topic_data[question].shortened_title}</div>
              <div className={"value "}>{add_unit(product[topic_data[question].column], topic_data[question].unit, true)}</div>
            </div>
          )
          count++
        }
      }
    }
    if(product.num_of_reviews && product.average_rating){
      const reviewLink = product['links'] ? `${this.chooseLink(product['links']).link}#pdp-other-notes` : null
      const maxStars = 5
      const percentCovered = 100 - (Math.round((product.average_rating / maxStars)*100))
      productDetails.push(
        <div key={'row-reviews'} className={"flex-row " + classNames({'alternate-color': count % 2 === 1})}>
          <div className={"label "}>Avg Rating</div>
          <div className={"value review"} onClick={()=>{this.goToSite(reviewLink, product.id, this.state.data_uuid, 'review_link')}}>
            <div className='star-container'>
              <FaStar className='star'/>
              <FaStar className='star'/>
              <FaStar className='star'/>
              <FaStar className='star'/>
              <FaStar className='star'/>
              <div className='star-overlay' style={{ width: `${percentCovered}%` }}/>
            </div>
            <span>{product.num_of_reviews}&nbsp;{product.num_of_reviews > 1 ? 'reviews' : 'review'}</span>
          </div>
        </div>
      )
      count++
    }
    if (product.additional_specs) {
      productDetails.push(
        <div key={'row-adt-'+STATIC_PRODUCT_ROWS} className={"flex-row additional-specs " + classNames({'alternate-color': count % 2 === 1})}>
          <div>Additional Specs</div>
        </div>
      )
      count++
      const additional_specs = product.additional_specs
      for (const spec in additional_specs) {
        productDetails.push(
          <div key={'row-ad-'+spec+STATIC_PRODUCT_ROWS} className={"flex-row " + classNames({'alternate-color': count % 2 === 1})}>
            <div className={"label "}>{additional_specs[spec].shortened_title}</div>
            <div
              className={"value " + classNames({'flex-wrap': additional_specs[spec].unit === 'additional_images'})}>
                {add_unit(additional_specs[spec].value, additional_specs[spec].unit, true)}
            </div>
          </div>
        )
        count++
      }
    }
    if (product.radar) {
      productDetails.push(<div className={'mt-sm'}><RadarComponent key={'radar'} data={product.radar} is_value={is_value} slug={this.state.product_slug} /></div>)
    }
    if (product.additional_colors) {
      productDetails.push(
        <div key={'row-adt-'+STATIC_PRODUCT_ROWS} className={"flex-row additional-specs " + classNames({'alternate-color': count % 2 === 1})}>
          <div>Available Colors</div>
        </div>
      )
      count++
      const additional_specs = product.additional_colors
        productDetails.push(
          <div key={'row-ad-'+0+STATIC_PRODUCT_ROWS} className={"flex-row additional_colors"}>
            <div
              className={"value additional-colors"}>
                {add_unit(additional_specs[0].value, additional_specs[0].unit, true)}
            </div>
          </div>
        )
    }
    return productDetails
  }

  goBackToQuestionX = (x) => {
    this.props.router.push(this.state.path + this.state.product_slug+'/' + (x) + '/')
  }

  toggleEditFilters = () => {
    this.setState({edit_filters_toggle_bool: !this.state.edit_filters_toggle_bool})
    // this.setState({sidebar_toggle: false})
  }

  render () {
    const { topic_data, matching_products, related_button_container, group_topic_container } = this.state
    const {progress, selections, constant_columns} = this.createFilters(topic_data, related_button_container, group_topic_container)
    let display_highend_products = []
    let display_value_products = []
    if (this.state.results_page) {
      let {
        value_picks,
        highend_picks,
      } = this.sortPicks(matching_products, this.state.product_slug)
      for (const product in highend_picks) {
          const productDetails = this.makeProductDetails(topic_data, highend_picks[product], constant_columns, false)
          display_highend_products.push(this.createDisplayProduct(highend_picks[product], productDetails, 'highend'))
      }
      for (const product in value_picks) {
        const productDetails = this.makeProductDetails(topic_data, value_picks[product], constant_columns, true)
        display_value_products.push(this.createDisplayProduct(value_picks[product], productDetails, 'value'))
      }
    }
    let sub_text = this.state.topic_data[this.state.current_question] ? this.state.topic_data[this.state.current_question].sub_text : ''
    const {
      exact_matches,
      // show_more,
      mounting,
      current_question,
      questions,
      results_page,
      close_sidebar,
      show_more_options,
      product_slug,
      show_more_highend,
      show_more_value,
      topic_status,
      sidebar_toggle,
      error_message
    } = this.state
    // let maintenance = true
    // if (maintenance === true) {
    //   return (
    //     <div className='simplybuy-container'>
    //       <div className={'product-picker'}>
    //       SimplyBuy is undergoing temporary maintenance. We should be back by 12:30pm PST!
    //       </div>
    //     </div>
    //   )
    // }
    if (error_message) {
      return (
        <div className='simplybuy-container'>
          <div className={'product-picker'}>
          {error_message}
          </div>
        </div>
      )
    }
    let navTabs = {}
    if (this.state.current_question > 0) {
      if (results_page) {
        navTabs['questions'] = {
          label: 'Results',
          logo: [<div key='btm-nav-results-img' className='img' />],
          // unfocused_logo: [<div class=src='/simplybuy-favicon-white.jpg' />],
          content: null
        }
      } else {
        navTabs['questions'] = {
          label: 'Questions',
          logo: [<div key='btm-nav-questions-img' className='img' />],
          content: null
        }
      }
      if (sub_text) {
        navTabs['question_description'] = {
          label: 'Description',
          logo: [<BsInfoCircle key='bsquestionsquare'/>],
          content: [
            <div key='btm-nav-description' className="sub-text" dangerouslySetInnerHTML={{ __html: sub_text }} />
          ]
        }
      } else {
        if (!results_page) {
          navTabs['question_description'] = {
            label: 'Description',
            logo: [<BsInfoCircle key='bsquestionsquare'/>],
            content: [
              <div key='btm-nav-description' className='p-md'>No description for this question.</div>
            ]
          }
        } else if (related_button_container.length > 0) {
          // related_button_container.push(group_topic_container)
          // console.log(related_button_container)
          let x = []
          x.push(
            <div key="remove-inverse" className='remove-inverse'>
              {related_button_container}
              {group_topic_container}
            </div>
          )
          // let x = related_button_container + group_topic_container
          let icon = null
          if (['snowboard', 'snowboard-binding', 'snowboard-boots'].includes(product_slug)) {
            icon = [<FaSnowboarding key='FaSnowboarding'/>]
          }
          navTabs['related_products'] = {
            label: 'Related Products',
            logo: icon,
            content: x
          }
        } else if (group_topic_container.length > 0) {
          let icon = null
          let x = []
          x.push(
            <div key="remove-inverse1" className='remove-inverse'>
              {group_topic_container}
            </div>
          )
          icon = [<BsFillGrid3X3GapFill key='BsFillGrid3X3GapFill'/>]
          navTabs['related_products'] = {
            label: 'More Products',
            logo: icon,
            content: x
          }
        }
      }
      if (selections.length > 0) {
        navTabs['edit_filters'] = {
          label: 'Edit Filters',
          logo: [<IoMdOptions key='bssliders'/>],
          content: [
            <div key='btm-nav-filters'>
              <div className={"your-filters "}>
                <div>Filters</div>
                {// UNCOMMENT CONSTANT_COLUMNS
                  /*<div>
                  <div key={'legend'} className="legend mt-none pb-none">
                    <span>Exact Specs</span>
                    <div className="color-key mr-none ml-sm"></div>
                    {this.state.results_page && this.state.exact_matches && (
                      <Switch labelLeft={true} color="#fa9f2f" containerClasses={"switch-container mt-sm"} label={'Show exact specs?'} checked={this.state.show_constants} switchChange={this.toggleSwitch}/>
                    )}
                  </div>
                </div>*/}
              </div>
              {selections}
            </div>
          ]
        }
      } else {
        navTabs['edit_filters'] = {
          label: 'Edit Filters',
          logo: [<IoMdOptions key='bsquestionsquare'/>],
          content: [
            <div key='btm-nav-filter' className='p-md'>No filters yet.</div>
          ]
        }
      }
    }
    return (
      <div>
        {Object.keys(navTabs).length > 0 && (
          <BottomNav
            height={"25%"}
            spaceBetween={"results_page"}
            close={close_sidebar}
            setCloseFalse={this.setCloseFalse}
            tabText={'Edit Filters'}
            open_toggle={sidebar_toggle}
            tabs={navTabs}
            default_select={'questions'}
            description_toggle_bool ={this.state.description_toggle_bool}
            related_products_toggle_bool ={this.state.related_products_toggle_bool}
            edit_filters_toggle_bool = {this.state.edit_filters_toggle_bool}
            stateChanger = {this.setState}
          >
          </BottomNav>
        )}
        <div className='simplybuy-container no-padding-right'>
          {mounting && (
            <div className="spinner-container">
              <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
          )}
          {!mounting && (
            <div className={'product-picker ' + classNames({'display-none': mounting, 'padding-top-50': results_page })} >
              {!this.state.results_page && this.state.current_question !== 0 && (
                <div className="progess-container-calc">
                  <ul className="progressbar-calc">
                    {progress}
                  </ul>
                </div>
              )}
              <div className={"product-picker-content " + classNames({'no-max-width': this.state.results_page })}>
                <Question
                  topic_data={topic_data}
                  current_question={current_question}
                  show_more_options={show_more_options}
                  product_slug={product_slug}
                  nextQuestion={this.nextQuestion}
                  recommended_column_num={this.state.recommended_column_num}
                  recommended_column={this.state.recommended_column}
                  makeSelection={this.makeSelection}
                  makeSelectionMultiple={this.makeSelectionMultiple}
                  toggleShowMoreOptions={this.toggleShowMoreOptions}
                  setWidgetValues={this.setWidgetValues}
                  input_values={this.state.input_values}
                  goBackToQuestionX={this.goBackToQuestionX}
                  topic_status={topic_status}
                  logged_in={this.props.logged_in}
                  related_button_container={this.state.related_button_container}
                  topic_name={this.state.topic_name}
                  topic_name_plural={this.state.topic_name_plural}
                  slider_data={this.state.slider_data}
                  goToSite={this.goToSite}
                />
                {questions[current_question]}
                {results_page && (
                  <div>
                    <BestResult
                      exact_matches={exact_matches}
                      mounting={mounting}
                      display_value_products={display_value_products.slice(0,3)}
                      display_highend_products={display_highend_products.slice(0,3)}
                      toggleShowMoreResults={this.toggleShowMoreResults}
                      toggleShowMoreHighendResults={this.toggleShowMoreHighendResults}
                      toggleShowMoreValueResults={this.toggleShowMoreValueResults}
                      show_more_value={show_more_value}
                      show_more_highend={show_more_highend}
                      toggleEditFilters={this.toggleEditFilters}
                      data_uuid={this.state.data_uuid}
                      related_button_container={this.state.related_button_container}/>
                    {/*<CompareResults value_picks={value_picks} highend_picks={highend_picks} topic_data={topic_data} constant_columns={constant_columns}/>*/}
                  </div>
                )}
                {results_page && show_more_highend && (
                  <Results products={display_highend_products.slice(1,3)} product_type={'highend'}/>
                )}
                {results_page && show_more_value && (
                  <Results products={display_value_products.slice(1,3)} product_type={'value'}/>
                )}
                {this.state.results_page && (
                  <div className={'mobile-only mt-lg'}>
                    {this.props.logged_in === 'true' && display_value_products.length > 0 && (
                      <div className=''>
                        <div className="btn-center-container result-options">
                          <button className="action-btn-gld continue-btn btn-m10 small" onClick={
                            () => { this.startFresh() }}>New Filters
                          </button>
                          <button className="action-btn-gld continue-btn btn-m10 small" onClick={
                            () => { this.toggleEditFilters() }}>Edit Filters
                          </button>
                          <button className="action-btn-gld continue-btn btn-m10 small" onClick={() => this.setState({related_products_toggle_bool : !this.state.related_products_toggle_bool})}>
                            {this.state.related_button_container.length ? "Related Products" : "More Products" }
                          </button>
                        </div>

                      </div>
                    )}
                    {this.props.logged_in === "false" && display_value_products.length > 0 && (
                      <div className=''>
                        <div className="btn-center-container result-options">
                        <button className="action-btn-gld continue-btn btn-m10 small" onClick={
                          () => { this.toggleEditFilters() }}>Edit Filters
                        </button>
                          <button className="action-btn-gld continue-btn btn-m10 small" onClick={
                            () => { this.startFresh() }}>New Filters
                          </button>
                          <button className="action-btn-gld continue-btn btn-m10 small" onClick={
                            () => {
                              this.showSaveResultsModal(
                                "Save Your Results",
                                "Create an account to have all your SimplyBuy results saved in one place!"
                              ) }}>Save Results
                          </button>
                              <button className="action-btn-gld continue-btn btn-m10 small" onClick={() => this.setState({related_products_toggle_bool : !this.state.related_products_toggle_bool})}>
                                {this.state.related_button_container.length ? "Related Products" : "More Products" }
                              </button>
                        </div>
                      </div>
                    )}
                    <div className="ta-center pb-md buy-coffee pt-xl">
                      <a href="https://www.buymeacoffee.com/simplybuy" className="standard-link">Enjoying simplybuy? Buy the developer a coffee</a> <BiCoffeeTogo href="https://www.buymeacoffee.com/simplybuy"/>
                    </div>
                  </div>
                )}
                {!this.state.results_page && this.state.current_question !== 0 && (
                  <div>
                    <div className="next-container">
                      {!this.state.has_seen_results && (
                        <button
                          disabled={this.state.next_disabled}
                          className="action-btn-gld continue-btn"
                          onClick={
                            () => { this.nextQuestion() }}
                          onMouseEnter={(e) => {this.highlightPopular()}}
                          onMouseLeave={(e) => {this.removeHighlight()}}
                            >
                          Next
                        </button>
                      )}
                      {this.state.has_seen_results && (
                        <button className="action-btn-gld continue-btn  results" onClick={
                          () => { this.nextQuestion(true) }}>See New Results
                        </button>
                      )}
                    </div>
                    <div className="sub-text hidden-sm-down" dangerouslySetInnerHTML={{ __html: sub_text }} />
                  </div>
                )}
              </div>
            </div>
          )}
          {current_question > 0 && (
            <div className="selection-container desktop">
              {this.state.results_page && (
                <div>
                  {this.props.logged_in === 'true' && (
                    <div className=''>
                      <div className="btn-center-container">
                        <button className="action-btn-gld continue-btn btn-m10 small xs" onClick={
                          () => { this.startFresh() }}>Start New
                        </button>
                      </div>
                    </div>
                  )}
                  {this.props.logged_in === 'false' && (
                    <div className=''>
                      <div className="btn-center-container">
                        <button className="action-btn-gld continue-btn btn-m10 xs small" onClick={
                          () => { this.startFresh() }}>Start New
                        </button>
                        <button className="action-btn-gld continue-btn btn-m10 xs small" onClick={
                          () => {
                            this.showSaveResultsModal(
                              "Save Your Results",
                              "Register now to have all your SimplyBuy results saved in one place!"
                            ) }}>Save Results
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className={"your-filters " + classNames({'no-margin': this.state.results_page})}>Filters</div>
              {selections}
            </div>
          )}
          <SaveResultsModal
            // handleSubmit={this.handleSubmit}
            show={this.state.show_save_results_modal}
            hide={() => { this.hideSaveResultsModal() }}
            results_url={this.state.results_url}
            data_uuid={this.state.data_uuid}
            current_question={this.state.current_question}
            product_slug={this.state.product_slug}
            handlelogin={this.props.handlelogin}
            router={this.props.router}
            title_text={this.state.title_text}
            description_text={this.state.description_text}
          />
        </div>
      </div>
    )
  }
}

ProductPicker.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  handlelogin: PropTypes.func,
  router: PropTypes.object,
  test: PropTypes.string
}

export default ProductPicker
