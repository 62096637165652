export default {
	HOME: '/',
	ABOUT: '/about/',
	BLOG_: '/blog/',
	BULKING: '/how-to-bulk',
	PRODUCT_PICKER_: `/product-picker/`,
	PRODUCT_PICKER: (slug, question) => `/product-picker/${slug}/${question}/`,
	SAVED_RESULTS: '/saved-results/',
	MANAGE_ALERTS: '/manage-price-alerts/',
	COMMISSION_REPORTS: '/commission-reports/',
	COMMISSION_ADMIN: '/commission-admin/',
	RECOMMENDATION_ENGINE: '/recommendation-engine/',
	PROFILE: '/profile/',
	BLOG: (title) => `/blog/${title}/`,
}
