import React, { Component } from "react";
import Slider from "react-slick";
import PropTypes from 'prop-types'
import LazyImg from './LazyImg.js'
import Image from 'next/image'
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
// import { withRouter } from 'react-router-dom'

class ProductSlider extends Component {
  render() {
    const settings = {
      slidesToShow: 4,
      slidesToScroll: 1,
      // infinite: true,
      autoplay: true,
      speed: 1500,
      autoplaySpeed: 1000,
      swipe: false,
      // lazyLoad: true,
      // cssEase: 'linear',
      pauseOnFocus: false,
      arrows: false,
      dots: false,
      className: 'product-slider center',
      pauseOnHover: false,
      // initialSlide: 4
    };
    let slider_data = this.props.slider_data
    let product_array = []
    for (let product in slider_data) {

      product_array.push(
        <div key={product+'product-slider'}>
          <Image
            src={slider_data[product].image}
            alt={slider_data[product].name}
            priority={true}
            onClick={() => {
              this.props.goToSite(slider_data[product].url, null, this.props.data_uuid, false) }}
            width={'85'}
            height={'85'}
            className='product-slide'
          />
        </div>
      )
    }
    if (product_array.length <= 5) {
      return (null)
    }
    // console.log(product_array)
    return (
      <div className="home-slick product-slick">
        <Slider {...settings}>
          {product_array}
        </Slider>
      </div>
    );
  }
}

ProductSlider.propTypes = {
  slider_data: PropTypes.array,
  goToSite: PropTypes.func
}

export default ProductSlider
