import React from "react";
import { PAGEURL } from "./../../constants"

class DataList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredOptions: [],
      textValue: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.showList = this.showList.bind(this);
    this.hideList = this.hideList.bind(this);
  }

  updateFilter(evt) {
    let value = "";

    if (evt) {
      value = evt.target.value;
    }
    let filteredOptions = [];
    for (let i = 0; i < this.props.options.length; i++) {
      // this is the idea of creating div//
      const item = this.props.options[i];
      if (item.value.toLowerCase().indexOf(value.toLowerCase()) != -1) {
        filteredOptions.push({
          id: item.id,
          value: item.value
        });
      }
    }
    this.setState({
      filteredOptions: filteredOptions,
      textValue: value
    });
  }

  handleClick(option) {
    if (option) {
      let slug = option.id.replace(' ', '-').toLowerCase()
      let path = PAGEURL.RECOMMENDATION_ENGINE + slug + '/'+ '0'
      this.props.router.push(path)
    } else {
      let slug = this.state.textValue.trim().replace(' ', '-').toLowerCase()
      let path = PAGEURL.RECOMMENDATION_ENGINE + slug + '/'+ '0'
      this.props.router.push(path)
    }

    // this.props.onSelectUser(option.id);
    // this.setState({ textValue: option.value });
    // this.hideList();
  }

  showList() {
    this.setState({
      expanded: true
    });
  }

  handleKeyDown () {
    if (event.key === 'Enter') {
      this.handleClick()
    }
  }

  hideList() {
    // whe we click that name after by this function the list hide.
    this.setState({
      expanded: false
    });
  }

  render() {
    let displayList = this.state.filteredOptions.map((option) => {
      return (
        <div key={'option-'+option} className={'search-option'} data-id={option.id} onClick={() => this.handleClick(option)}>
          {option.value}
        </div>
      );
    });
    const { textValue } = this.state;
    return (
      <div>
        <div className="search-container">
          <input
            type="text"
            onKeyDown={() => {this.handleKeyDown()}}
            value={textValue}
            onChange={this.updateFilter.bind(this)}
            onFocus={this.showList}
            className={'data-list'}
            placeHolder={'Enter Product Category...'}
          />
          <span className="action-btn-gld blue small continue-btn" onClick={() => this.handleClick()}>Go</span>
        </div>
        <div className={'search-options-container'}>{this.state.expanded && displayList}</div>
      </div>
    );
  }
}

export default DataList;
