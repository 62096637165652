import React, { createContext, useState } from "react";


const AppContext = createContext({
  logged_in: 'false',
  handleLogout: ()=>{},
  handleLogin: ()=>{}
});

export default AppContext;
