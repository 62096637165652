import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class Menu extends React.Component {
  render () {
    const menuItems = this.props.menuItems
    return (
      <div className={'dropdown-menu-container ' + classnames({'show': this.props.show})}>
        {menuItems}
      </div>
    )
  }
};


class Dropdown extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     show: false,
  //   };
  // }

  state = {
    show: this.props.show
  }
  // handleMouseHover() {
  //   this.setState(this.toggleHoverState);
  // }

  toggleShow = () => {
    this.setState({show: !this.state.show,})
  }

  onBlur = () => {
    if (this.state.show === true) {
      this.setState({show: false})
    }
  }

  // onMouseEnterHandler = () => {
  //   if (this.state.show === false) {
  //     this.setState({show: true})
  //   }
  // }

  render () {

    const { menuItems } = this.props
    const menu = []

    for (const item in menuItems) {
      menu.push(
        <div
          key={item+'item'}
          onClick={ () => menuItems[item].onClick() }
          className=""
        >
        {menuItems[item].buttonTitle}
      </div>)
    }
    const dropdownClass =  this.state.show ? 'dropdown-padding dropdown-open' : 'dropdown-padding'
    return (
      <div onMouseLeave={this.onBlur} onClick={this.toggleShow}>
        <div className={dropdownClass}>
          {this.props.children}
        </div>
        <Menu menuItems={menu} show={this.state.show}/>
      </div>
    )
  }
}

Dropdown.propTypes = {
  menuItems: PropTypes.array,
  show: PropTypes.bool
}

export default Dropdown
