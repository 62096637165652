import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { FormikField } from '../'
import { withRouter } from 'react-router-dom'
import Modal from 'react-overlays/lib/Modal'
import services from '../services'
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

const backdropStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#000',
  opacity: 0.5
}

class ContactUs extends React.Component {
  state = {
    show: this.props.show,
    register: false
  }

  renderBackdrop (props) {
    return <div {...props} style={backdropStyle} onClick={this.props.hide} />
  }

  hide = (e) => {
    this.setState({ show: false })
  }

  routePage (page, event) {
    this.props.store.routePage(page)
    event.preventDefault()
  }

  render () {
    return (
      <div>
        {this.props.show && (
          this.renderBackdrop()
        )}
        <Modal
          onHide={ this.props.hide }
          className={ 'member-modal login'}
          aria-labelledby="modal-label"
          show={ this.props.show }
          >
            <div className="modal-content">
              <div className={'w-100'}>
                <h3 className="mt-none">Contact Us</h3>
                <Formik
                  initialValues = {{
                    email: this.props.email || '',
                    subject: '',
                    text: '',
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string().email().required('Required'),
                    subject: Yup.string().required('Required'),
                    text: Yup.string().required('Required')
                  })}
                  onSubmit={ async (values, { setSubmitting, setErrors }) => {
                    const response = await services.post_v2("api/contact-us/", values)
                    if (response.statusCode===200) {
                      this.props.hide()
                      toast('Thank you for reaching out! Your message has been received.')
                    } else if (response.email) {
                      setErrors({username:"Please enter a valid email."})
                    } else {
                      toast('There was an error sending your message. We have been notified and will fix it ASAP.')
                    }
                    setSubmitting(false)
                  }}
                >
                  {props => (
                    <Form>
                      <div className="">
                        <FormikField name="email" label="Email" required={true} >
                          <Field className="form-field" name="email" placeholder="Email" type="text" />
                        </FormikField>
                        <FormikField name="subject" label="Subject" required={true} >
                          <Field className="form-field" name="subject" placeholder="Subject" type="text" />
                        </FormikField>
                        <FormikField name="text" label="Confirm Password" required={true} >
                          <Field as="textarea" className="form-field contact-textarea" name="text" placeholder="Type message here" type="text" ></Field>
                        </FormikField>
                        <div className="btn-center-container">
                          <button type="submit" className="action-btn-gld">Submit</button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
          </div>
        </Modal>
      </div>
    )
  }
}

ContactUs.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  handlelogin: PropTypes.func,
  // results_url: PropTypes.func
}

// export default SaveResultsModal
export default ContactUs
