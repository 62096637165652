import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
// import { withRouter } from 'react-router-dom'
import services from '../services'
// import ReactGA from 'react-ga'
import { APIURL } from './../../constants'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'

import { toast } from 'react-toastify';

toast.configure()


// @inject('store')
// @observer
export default function CustomGoogleButton({handlelogin, closeMenu, buttonText, separatorText, callBack}) {
  const [showLoginModal, setShowLoginModal] = useState(false);
  // const { slug } = router.query
  // const hideLoginModal = () => {
  //   setShowLoginModal(false)
  // }

  const googleLogin = useGoogleLogin({
    // flow: 'auth-code',
     onSuccess: (google_response) => {
       axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${google_response.access_token}` },
        }).then(res => {
          const test = res.data
          services.post_v2("api/google-sign-in/", test).then((response) => {
            if (response.token) {
              localStorage.setItem('token', response.token);
              handlelogin(null, response.token)
              if (localStorage.getItem('calculator_values')) {
                let response = services.post_v2(APIURL.UPDATE_USER_INFO, {
                  calculator_values: JSON.parse(localStorage.getItem('calculator_values'))
                })
                localStorage.removeItem('calculator_values')
              }
              callBack()
            } else {
              toast('An Error Occured')
            }
          })
        })
    },
   onError: (error) => {toast('An Error Occured')},
  })

  return (
    <div>
      <button className="google-signin" onClick={() =>
          googleLogin()}>
          <img src={"/google-logo.webp"} alt="" />
          {buttonText}
      </button>
      <div className='or-seperator'>
        <span>
          {separatorText}
        </span>
      </div>
    </div>
  )
}
