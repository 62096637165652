import React, { Component } from "react";
import Switch from "react-switch";
import { withRouter } from 'react-router-dom'
// import classNames from 'classnames'
import PropTypes from 'prop-types'

class DefaultSwitch extends Component {
  // constructor() {
  //   super();
  //   // this.state = { checked: false };
  //   // this.handleChange = this.handleChange.bind(this);
  // }

  handleChange = () => {
    this.props.switchChange()
  }

  render() {
    return (
      <label>
        <div className={this.props.containerClasses}>
        {this.props.label && this.props.labelLeft && (
          <small className="mr-md">{this.props.label}</small>
        )}
        <Switch
          id={'switch'}
          onColor={this.props.onColor || '#fccd93'}
          offColor={this.props.offColor}
          onChange={this.handleChange}
          checked={this.props.checked}
          uncheckedIcon={this.props.uncheckedIcon === false ? false : undefined}
          checkedIcon={this.props.checkedIcon === false ? false : undefined}
          className={this.props.className} />
        {this.props.label && this.props.notSmall && (
            <span className="ml-md">{this.props.label}</span>
        )}
        {this.props.label && !this.props.labelLeft && !this.props.notSmall && (
          <small className="ml-md">{this.props.label}</small>
        )}
        </div>
      </label>
    );
  }
}

DefaultSwitch.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  className: PropTypes.string,
  checkedIcon: PropTypes.bool,
  uncheckedIcon: PropTypes.bool,
  notSmall: PropTypes.bool
}

export default DefaultSwitch
