import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Nouislider from "@x1mrdonut1x/nouislider-react";
import 'react-toastify/dist/ReactToastify.css'
import "nouislider/distribute/nouislider.css"
import classNames from 'classnames'
import { toast } from 'react-toastify'
import Switch from './Switch.js'
import services from '../services'
import { APIURL } from './../../constants'
import { formatShoeSizeMens } from './widgets/formats'

const QN = 4

toast.configure()

class SnowboardBindingSizeWidget extends Component {

  constructor (props) {
    super(props)
    // console.log('constructor')
    const td = this.props.topic_data
    this.state = {
      shoeSize: this.checkIfValue(td, 'shoeSize') ? td[QN].input_values['shoeSize'] : 0,
      shoeStart: this.checkIfValue(td, 'shoeSize') ? td[QN].input_values['shoeSize'] : 9.5,
      show_sliders: td && td[QN].input_values ? true : false,
      shoeMin: 0,
      shoeMax: 14,
      start: [],
      lowerSize: 0,
      upperSize: 0,
      ref: null,
      rendering: true,
      // show_metric: td && td[QN].input_values && td[QN].input_values['show_metric'] ? true : false,
      user_info_save: false,
      initial: true
    }
  }

  checkIfValue (td, key) {
    return (td && td[QN].input_values && td[QN].input_values[key] && td[QN].input_values[key])
  }

  componentDidMount = () => {
    const td = this.props.topic_data
    if (this.props.logged_in === 'true') {
      services.get_v2(APIURL.USER_PROFILE, {params: {}}).then((response) =>{
        let user_info = response
        let calculate_needed = false
        let user_info_save = true
        if (user_info.shoe_size && !this.checkIfValue(td, 'shoeSize')) {
          calculate_needed = true
          user_info_save = false
        }
        this.setState({
          initial: false,
          user_profile: true,
          shoeStart: this.checkIfValue(td, 'shoeSize') ? td[QN].input_values['shoeSize'] : false || user_info.shoe_size || 9.5,
          calculate_needed: calculate_needed,
          user_info_save: user_info_save
        })
      })
    } else if (typeof window !== "undefined") {
      let user_info = JSON.parse(window.localStorage.getItem('calculator_values'))
      if (user_info) {
        let calculate_needed = false
        if (user_info.shoeSize && !this.checkIfValue(td, 'shoeSize')) {
          calculate_needed = true
        }
        this.setState({
          initial: false,
          user_profile: true,
          shoeStart: this.checkIfValue(td, 'shoeSize') ? td[QN].input_values['shoeSize'] : false || user_info.shoeSize || 9.5,
          calculate_needed: calculate_needed,
        })
      } else {
        this.setState({
          initial: false
        })
      }
    }
  }

  componentDidUpdate = () => {
    if (this.state.calculate_needed) {
      let user_info = JSON.parse(window.localStorage.getItem('calculator_values'))
      this.calculateSizeRange(this.state.shoeStart)
    }
  }

  createRange = (min_size, max_size) => {
    // console.log('createRange')
    const {lowerSize, upperSize} = this.state
    var sizeList = [];
    if (min_size && max_size) {
      for (let i = min_size; i <= max_size; i++) {
          sizeList.push(String(parseInt(i)));
      }
    } else if (lowerSize && upperSize) {
      for (let i = lowerSize; i <= upperSize; i++) {
          sizeList.push(String(parseInt(i)));
      }
    }
    if (this.state.show_sliders) {
      this.props.setWidgetValues(sizeList, null, {
        shoeSize: this.state.shoeSize,
      }, false, this.state.user_info_save)
    }
  }

  getStartingRange = (topic_data) => {
    let shoe_min = this.state.shoeMin
    let shoe_max = this.state.shoeMax
    let shoe_start = this.state.shoeStart
    let shoeSize = this.state.shoeSize
    shoe_min = 4
    shoe_max = 14
    this.setState({
      shoeMin: shoe_min,
      shoeMax: shoe_max,
      // shoeStart: shoe_start,
      rendering: false
    })
    this.createRange(shoe_min, shoe_max)
  }

  calculateSizeRange = (shoeSize) => {
    if (this.state.calculate_needed) {
      this.setState({calculate_needed: false})
    }
    // let shoeSize = this.state.shoeSize
    const { topic_data } = this.props
    let min_range = 0
    let max_range = 0
    let values = []
    shoeSize = parseFloat(shoeSize)
    if (topic_data[1].value === 'womens'){
      if (shoeSize < 6.5) {
        values.push('S')
      }
      if (shoeSize >= 6.5 && shoeSize <= 8.5) {
        values.push('M')
      }
      if (shoeSize >= 9 && shoeSize <= 11) {
        values.push('L')
      }
      if (shoeSize >= 11.5) {
        values.push('XL')
      }

      if (shoeSize >= 6 && shoeSize <= 8) {
        values.push('S/M')
      } else if (shoeSize >= 8.5 && shoeSize <= 10.5) {
        values.push('M/L')
      } else if (shoeSize >= 11){
        values.push('L/XL')
      }

    } else {
      if (shoeSize <= 8) {
        values.push('S')
      }
      if (shoeSize >= 8.5 && shoeSize <= 10.5) {
        values.push('M')
      }
      if (shoeSize >= 10.5 && shoeSize <= 13) {
        values.push('L')
      }
      if (shoeSize >= 13) {
        values.push('XL')
      }

      if (shoeSize <= 9 && shoeSize >= 7) {
        values.push('S/M')
      } else if (shoeSize <= 11 && shoeSize > 9) {
        values.push('M/L')
      } else if (shoeSize > 11){
        values.push('L/XL')
      }
    }

    if(topic_data[1].value === 'womens' && shoeSize >= 11.5) {
      toast.dismiss()
      toast.info(
        <div>
          <div className={'toast-text'}>
            There may be few or no women&#39;s bindings at this size. You may want to consider switching to men&#39;s bindinds!
          </div>
          <div className={'toast-text pt-sm'}>
            When using the men&#39;s sizing calculator subtract 1.5 from you women&#39;s boot size.
          </div>
          <div className={'click-here'} onClick={() => {this.props.goBackToQuestionX(1)}}>
            Click Here to Switch
          </div>
        </div>,
        {
          position: "bottom-left",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      })
    }
    this.props.setWidgetValues(
      values,
      null,
      {
        shoeSize: shoeSize,
      },
      true,
      this.state.user_info_save
    )

  }

  render () {
    const {
      // weight,
      shoeSize,
      shoeStart,
      shoeMin,
      shoeMax,
      rendering,
    } = this.state
    if (shoeMin === 0) {
      this.getStartingRange(this.props.topic_data)
    }

    return (
      <div>
      {rendering && (
        <div className="spinner-container">
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      )}
      {!rendering && (
        <div className="snowboard-widget">
          <div className="calc-sliders">
          <h1 id="size-calc">Size Calculator</h1>
            <div>
              <div>
                <div className="slider-container">
                  <Nouislider
                    onUpdate={(value) => {this.setState({shoeSize: value})}}
                    step={.5} range={{ min: shoeMin, max: shoeMax }}
                    start={[shoeStart]}
                    tooltips={formatShoeSizeMens(shoeMax)}
                  />
                  <div className="slider-label">{this.props.topic_data[1].value ==='womens' ? "Women's ": "Men's "}Boot Size</div>
                </div>
              </div>
            </div>
            <div className="btn-center-container">
              <button className={"action-btn-gld continue-btn btn-m10 small"} onClick={
                () => {
                  this.calculateSizeRange(shoeSize)
                  let element = document.getElementById('bottom-div')
                  if (element.getBoundingClientRect().bottom + 50 >= (window.innerHeight || document.documentElement.clientHeight)) {
                    let element1 = document.getElementById('size-calc')
                    element1.scrollIntoView({'behavior': 'smooth'})
                  }
                }}>Get My Sizes
              </button>
            </div>
          </div>
          <div id="bottom-div"></div>
          <div className="size-adjust-text pt-sm"><strong>Important Note</strong> - Some brands use combined sizes such as &#34;M/L&#34;. You will not be recommended these brands without having a combined size selected: Arbor, Bataleon, GNU, Rome, Roxy, and Rossignol.</div>
        </div>
      )}
      </div>
    )
  }
}

SnowboardBindingSizeWidget.propTypes = {
  topic_data: PropTypes.object,
  goBackToQuestionX: PropTypes.func,
  makeSelectionMultiple: PropTypes.func,
  logged_in: PropTypes.string,
}

export default SnowboardBindingSizeWidget
