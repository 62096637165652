import React from "react";
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

export const BottomNav = (
  {
    height,
    spaceBetween,
    default_select,
    children,
    tabs,
    close,
    setCloseFalse,
    tabText,
    open_toggle,
    description_toggle_bool,
    related_products_toggle_bool,
    stateChanger,
    edit_filters_toggle_bool
  }
) => {
  const [yPosition, setY] = React.useState('120%');
  const [open, setOpen] = React.useState(false);
  const [closeSideBar, setCloseSideBar] = React.useState(close);
  const [selected, setSelected] = React.useState(default_select);
  const [content, setContent] = React.useState(null);


  const toggleMenu = (tab_slug) => {
    if (tab_slug == selected && open && (tab_slug == 'question_description' || tab_slug == 'edit_filters')){
      closeMenu()
    } else if (tab_slug === default_select) {
      setSelected(tab_slug)
      setY('120%');
      setOpen(false)
    } else {
      if (selected !== default_select && tab_slug !== selected) {
        setSelected(tab_slug)
      } else if (yPosition === '30%') {
        setSelected(null)
        setY('120%');
        setOpen(false)
      } else {
        setSelected(tab_slug)
        setY(height);
        setOpen(true)
      }
    }
  };

  const closeMenu = () => {
    setY('120%');
    setOpen(false)
    setSelected(null)
    setSelected(default_select)
  }
  if(closeSideBar !== close) {
    closeMenu()
    setCloseSideBar(close)
    setCloseFalse()
  }
  React.useEffect(() => {
    if(description_toggle_bool){
      toggleMenu('question_description')
      stateChanger({description_toggle_bool : false})
      // Un comment below if you want info intro icon, to vanish with a transition after being clicked once
      // let infoButton = document.getElementById('InfoIntro')
      // infoButton.addEventListener("transitionend", () => {
        // infoButton.classList.add('hideIcon')
      // })
      // infoButton.classList.add('remove')
    }
    if(related_products_toggle_bool){
      toggleMenu('related_products')
      stateChanger({related_products_toggle_bool : false})
    }
    if(edit_filters_toggle_bool){
      toggleMenu('edit_filters')
      stateChanger({edit_filters_toggle_bool : false})
    }
    // Removes scrolling from body while bnavs open
    if(open){
      document.body.style.overflow = 'hidden'
    }else{
      document.body.style.overflow = 'auto'
    }
  })

  React.useEffect(() => {
    setY(yPosition);
    setOpen(false)
  }, []);

  React.useEffect(() => {
    if (open_toggle === false) {
      //do nothing
    } else {
      toggleMenu()
      open_toggle = false
      // setOpenToggle(false)
    }
  }, [open_toggle]);

  let navTabs = []
  for (let tab in tabs) {
    navTabs.push(
      <div
        key={'tab-'+tab}
        onClick={() => toggleMenu(tab)}
        className={"toggle-menu2 bottom-nav " + classNames({'nav-selected': (selected === tab)})}
        // style={{left: `${width}px`}}
      >
        <div className='test'>{tabs[tab].logo}</div>
        <div>{tabs[tab].label}</div>

      </div>
    )
  }
  // let display = 'block'
  // if (yPosition === "120%") {
  //   display = 'none'
  // }
  return (
    <React.Fragment>
    <div className={'navTabs ' + classNames({'space-between': spaceBetween})}>
      {navTabs}
    </div>
      <div
        className={"side-bar-backdrop " + classNames({'open': open})}
        onClick={() => closeMenu()}
      >
      </div>
      <div
        className="side-bar btm-nav"
        style={{
          transform: `translatey(${yPosition})`,
          width: '100%',
          // display: display
          // minHeight: height
        }}
      >
        <div className="content">
          <div className="overflow-container">
            <div className="selection-container sidebar">
              {selected && tabs[selected].content && (
                tabs[selected].content
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BottomNav
