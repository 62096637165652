import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const usePageTracking = (router) => {
  const location = router.asPath;
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      // ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ADS);
      ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: "pageview", page: location });
    }
  }, [initialized, location]);
};

export default usePageTracking;
