import React, { Component } from "react";
import Slider from "react-slick";
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
// import { withRouter } from 'react-router-dom'

class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 9000,
    };
    return (
      <div className="home-slick">
        <div className="slick-title">What people are saying about SimplyBuy</div>
        <Slider {...settings}>
          <div className="slide-content">
            <div>
              This tool is going to get you where you want to go.
              Used it to pick both my boards this year, and both have been fantastic.
            </div>
            <div className="slider-bottom">
              <div>Reddit User Dischucker</div>
              <div>Snowboards</div>
            </div>
          </div>
          <div className="slide-content">
            <div>
              Nice tool! Been a sales rep in the ski business for 37 years, and this is the best tool I have seen.
            </div>
            <div className="slider-bottom">
              <div>Reddit User coupdevill</div>
              <div>Skis</div>
            </div>
          </div>
          <div className="slide-content">
            <div>
              This is an awesome website! Well done.
              Been snowboarding for decades and this is by far the best tool I&#39;ve found that gives concise explanations for the various design features.
            </div>
            <div className="slider-bottom">
              <div>Reddit User LoungingLemur2</div>
              <div>Snowboards</div>
            </div>
          </div>
          <div className="slide-content">
            <div>
              Damn, I was thinking of getting a new pair of skis and got it down to 3 different skis, and this algorithm literally gave me two of the skis + 4 other cool options, 2 of which I didn&#39;t even know about.
            </div>
            <div className="slider-bottom">
              <div>Reddit User Jesus_mf_christ</div>
              <div>Skis</div>
            </div>
          </div>
          <div className="slide-content">
            <div>
              Pumped out the exact ski I want! Sick Day 94. Very cool man, I did a lot of research to land on the same result.
            </div>
            <div className="slider-bottom">
              <div>Reddit User HamRove</div>
              <div>Skis</div>
            </div>
          </div>
          <div className="slide-content">
            <div>
              Damn this is actually very impressive.
              I’ve been researching boards relatively extensively and have a good idea of what I want.
              Went through it a few times for fun, once for free ride and once for powder boards, ended up showing me 5-6 of my top picks!
            </div>
            <div className="slider-bottom">
              <div>Reddit User DatTallGerman</div>
              <div>Snowboards</div>
            </div>
          </div>
          <div className="slide-content">
            <div>
              Tried it out and omg it is really helpful. I really appreciate this. Helpful with making ski buying not an overwhelming experience.
            </div>
            <div className="slider-bottom">
              <div>Reddit User majuhomepl</div>
              <div>Skis</div>
            </div>
          </div>
          <div className="slide-content">
            <div>
              Cool tool! I wish this was available when first starting out.
            </div>
            <div className="slider-bottom">
              <div>Reddit User adamwest124</div>
              <div>Mountain Bikes</div>
            </div>
          </div>
          <div className="slide-content">
            <div>
              Dude, thank you.
              I&#39;m an experienced rider who knows nothing about boards and have ridden the same board for 15 years across all terrain because its too much effort to figure this out.
            </div>
            <div className="slider-bottom">
              <div>Reddit User DeepDiveExplorer</div>
              <div>Snowboards</div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

export default SimpleSlider
