import * as Sentry from '@sentry/browser'

const REPORT_TO_SENTRY = process.env.NODE_ENV === 'production'

export const logInit = () => {
  if (REPORT_TO_SENTRY) {
    Sentry.init({
      dsn: "https://ffe23113959946faaa986b4ccd7b9b81@o1032396.ingest.sentry.io/5999351",
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          console: false
        })
      ],
    })
  }
}

export const logError = (error, info) => {
  if (REPORT_TO_SENTRY) {
    Sentry.captureException(error)
  } else {
    console.log(error, info)
  }
}

export const logInfo = (message) => {
  if (REPORT_TO_SENTRY) {
    Sentry.captureMessage(message)
  } else {
    console.log(message)
  }
}
