export default {

  RECOMMENDED_SELECTIONS: {
    'barbell': {
      user_type: ['general_purpose'],
      show_tip: 2,
      power_lifting: {
        type_of_barbell: [],
        weight: ['20'],
        length: ['0'],
        diameter: ['28.5', '29'],
        whip: ['low'],
        center_knurling: ['True'],
        knurling_depth: ['medium', 'aggressive'],
        hand_markings: ['powerlifting', 'both'],
        sleeve_construction: ['bushing'],
        min_tensile_strength: ['190000'],
        shaft_finish: ['hard_chrome', 'zinc', 'cerakote', 'stainless_steel'],
        sleeve_finish: ['hard_chrome', 'zinc','cerakote', 'stainless_steel']
      },
      olympic_lifting: {
        weight: ['20'],
        length: ['0'],
        diameter: ['28'],
        whip: ['good', 'great'],
        center_knurling: ['False'],
        knurling_depth: ['medium'],
        hand_markings: ['olympic', 'both'],
        sleeve_construction: ['needle'],
        min_tensile_strength: ['190000'],
        shaft_finish: ['hard_chrome', 'cerakote', 'stainless_steel'],
        sleeve_finish: ['hard_chrome', 'cerakote', 'stainless_steel']
      },
      general_purpose: {
        weight: ['20'],
        length: ['0'],
        diameter: ['28.5'],
        knurling_depth: ['medium'],
        whip: ['low','good'],
        center_knurling: ['0'],
        hand_markings: ['both'],
        sleeve_construction: ['bushing'],
        min_tensile_strength: ['190000'],
        shaft_finish: ['hard_chrome', 'zinc', 'cerakote', 'stainless_steel'],
        sleeve_finish: ['hard_chrome', 'zinc', 'cerakote', 'stainless_steel']
      }
    },
    'snowboard': {
      show_tip: 4,
      terrain_type: ['all_mountain'],
      alternate: {
        question_number: 2,
        column: 'ability_level',
        value: 'beginner'
      },
      all_mountain: {
        shape: ['directional_twin'],
        flex: ['medium', 'stiff'],
        base: ['sintered'],
        magne_traction: ['0'],
        rocker_type: ['rocker_camber_rocker', 'camber_rocker_camber'],
        brand: ['0']
      },
      freestyle: {
        shape: ['true_twin'],
        flex: ['soft', 'medium'],
        base: ['sintered'],
        magne_traction: ['False'],
        rocker_type: ['rocker_camber_rocker', 'camber'],
        brand: ['0']
      },
      freeride: {
        shape: ['directional', 'volume_shifted'],
        flex: ['stiff'],
        base: ['sintered'],
        magne_traction: ['0'],
        rocker_type: ['rocker_camber', 'rocker_camber_rocker', 'camber_rocker_camber'],
        brand: ['0']
      },
      powder_board: {
        shape: ['directional', 'volume_shifted'],
        flex: ['stiff'],
        base: ['sintered'],
        magne_traction: ['0'],
        rocker_type: ['rocker_camber', 'rocker_camber_rocker', 'camber_rocker_camber'],
        brand: ['0']
      },
      splitboard: {
        shape: ['directional'],
        flex: ['stiff'],
        base: ['sintered'],
        magne_traction: ['False'],
        rocker_type: ['rocker_camber', 'rocker_camber_rocker'],
        brand: ['0']
      },
      beginner: {
        shape: ['directional_twin'],
        flex: ['soft', 'medium'],
        base: ['0'],
        magne_traction: ['False'],
        rocker_type: ['rocker_camber_rocker'],
        brand: ['0']
      },
    },
    'ski': {
      show_tip: 4,
      terrain_type: ['all_mountain'],
      alternate: {
        question_number: 2,
        column: 'ability_level',
        value: 'beginner',
      },
      all_mountain: {
        tail_tip: ['partial_twin'],
        turn_radius: ['medium'],
        rocker_type: ['rocker_camber_rocker'],
        brand: ['0'],
        package: ['only_skis'],
        style: ['balanced']
      },
      big_mountain: {
        tail_tip: ['partial_twin'],
        turn_radius: ['medium'],
        rocker_type: ['rocker_camber_rocker', 'rocker'],
        brand: ['0'],
        package: ['only_skis'],
        style: ['balanced']
      },
      alpine_touring: {
        tail_tip: ['flat', 'partial_twin'],
        turn_radius: ['medium'],
        rocker_type: ['rocker_camber', 'rocker_camber_rocker'],
        brand: ['0'],
        package: ['only_skis'],
        style: ['balanced']
      },
      carving: {
        tail_tip: ['flat'],
        turn_radius: ['short'],
        rocker_type: ['camber', 'rocker_camber_rocker', 'rocker_camber'],
        brand: ['0'],
        package: ['skis_and_bindings'],
        style: ['balanced', 'charging']
      },
      'park_&_pipe': {
        tail_tip: ['full_twin'],
        turn_radius: ['medium'],
        rocker_type: ['rocker_camber_rocker'],
        brand: ['0'],
        package: ['only_skis'],
        style: ['playful'],
      },
      powder: {
        tail_tip: ['partial_twin'],
        turn_radius: ['medium', 'long'],
        rocker_type: ['rocker_camber_rocker'],
        brand: ['0'],
        package: ['only_skis'],
        style: ['balanced']
      },
      beginner: {
        tail_tip: ['flat'],
        turn_radius: ['short', 'medium'],
        rocker_type: ['rocker_camber', 'rocker_camber_rocker'],
        brand: ['0'],
        package: ['skis_and_bindings'],
        style: ['balanced', 'playful']
      },
    },
    'ebike': {
      show_tip: 3,
      primary_use: ['city_adventure'],
      city_adventure: {
      },
      mountain_adventure: {
      },
      city_professional: {
      },
      city_commute: {
      },
    },
    'mountain-bike': {
      show_tip: 4,
      experience_level: [],
      alternate: {
        question_number: 3,
        column: 'riding_type',
        value: 'cross_country',
      },
      entry: {
        riding_type: ['trail'],
        rear_suspension: ['hardtail', 'medium'],
        wheel_size: ['29"'],
        frame_material: ['aluminum_alloy'],
        handlebar_shape: ['riser'],
        electric_drive_train: ['False'],
        brand: ['0']
      },
      intermediate: {
        riding_type: ['trail'],
        rear_suspension: ['medium'],
        wheel_size: ['29"'],
        frame_material: ['aluminum_alloy', 'carbon_fiber'],
        handlebar_shape: ['riser'],
        electric_drive_train: ['False'],
        brand: ['0']
      },
      advanced: {
        riding_type: ['enduro'],
        rear_suspension: ['medium', 'large'],
        wheel_size: ['29"'],
        rear_suspension: ['medium'],
        frame_material: ['carbon_fiber'],
        handlebar_shape: ['riser'],
        electric_drive_train: ['False'],
        brand: ['0']
      },
      expert: {
        riding_type: ['enduro'],
        rear_suspension: ['medium', 'large'],
        wheel_size: ['29"'],
        frame_material: ['carbon_fiber'],
        handlebar_shape: ['riser'],
        electric_drive_train: ['0'],
        brand: ['0']
      },
      cross_country: {
        rear_suspension: ['hardtail', 'firm'],
        wheel_size: ['29"'],
        frame_material: ['carbon_fiber'],
        handlebar_shape: ['0'],
        electric_drive_train: ['False'],
        brand: ['0']
      },
    },
    'snowboard-binding': {
      show_tip: 5,
      terrain_type: ['all_mountain'],
      alternate: {
        question_number: 2,
        column: 'ability_level',
        value: 'beginner',
      },
      beginner: {
        brand: ['0'],
        flex: ['soft', 'medium'],
        mount_pattern: ['2x4'],
        style: ['strap']
      },
      all_mountain: {
        brand: ['0'],
        flex: ['medium','stiff'],
        mount_pattern: ['2x4'],
        style: ['strap']
      },
      freestyle: {
        brand: ['0'],
        flex: ['medium'],
        mount_pattern: ['2x4'],
        style: ['strap']
      },
      freeride: {
        brand: ['0'],
        flex: ['stiff', 'very_stiff'],
        mount_pattern: ['2x4'],
        style: ['strap']
      },
    },
    'snowboard-boots': {
      show_tip: 3,
      ability_level: ['intermediate'],
      beginner: {
        brand: ['0'],
        flex: ['soft', 'medium'],
        lacing_system: ['boa_lacing'],
        binding_type: ['strap'],
        heat_moldable: ['0']
      },
      intermediate: {
        brand: ['0'],
        flex: ['medium'],
        lacing_system: ['boa_lacing'],
        binding_type: ['strap'],
        heat_moldable: ['True']
      },
      advanced: {
        brand: ['0'],
        flex: ['medium', 'stiff'],
        lacing_system: ['boa_lacing'],
        binding_type: ['strap'],
        heat_moldable: ['True']
      },
      expert: {
        brand: ['0'],
        flex: ['stiff', 'very_stiff'],
        lacing_system: ['boa_lacing'],
        binding_type: ['strap'],
        heat_moldable: ['True']
      },
    },
    'monitor': {
      show_tip: 2,
      usage: ['mixed_use'],
      alternate: {
        question_number: 2,
        column: 'screen_type',
        value: 'ultrawide',
      },
      ultrawide: {
        brand: ['0'],
        size: ['34', '40'],
        resolution: ['1440p'],
        panel: ['VA', 'IPS',],
        refresh_rate: ['144'],
        curved: ['True']
      },
      mixed_use: {
        brand: ['0'],
        screen_type: ['standard'],
        resolution: ['1440p'],
        size: ['27'],
        panel: ['IPS'],
        refresh_rate: ['144'],
        curved: ['False'],
        hdr: ['400'],
      },
      office: {
        brand: ['0'],
        screen_type: ['standard'],
        resolution: ['1440p'],
        size: ['27'],
        panel: ['IPS','VA'],
        refresh_rate: ['60'],
        curved: ['False'],
        hdr: ['0'],
      },
      gaming: {
        brand: ['0'],
        screen_type: ['standard'],
        resolution: ['1440p'],
        size: ['27', '32'],
        panel: ['IPS'],
        refresh_rate: ['144'],
        curved: ['False'],
        hdr: ['400'],
      },
      media_creation: {
        brand: ['0'],
        screen_type: ['standard'],
        resolution: ['1440p'],
        size: ['27'],
        panel: ['IPS'],
        refresh_rate: ['144'],
        hdr: ['400'],
        curved: ['False']
      },
    },
    'headphones': {
      show_tip: 2,
      usage: ['fitness'],
      fitness: {
        brand: ['0'],
        wireless: ['True'],
        bass_heavy: ['False'],
        microphone_score: ['0'],
        noise_cancelling: ['True'],
        type: ['in-ear'],

      },
      travel: {
        brand: ['0'],
        wireless: ['True'],
        bass_heavy: ['False'],
        microphone_score: ['0'],
        noise_cancelling: ['True'],
        type: ['in-ear', 'over-ears'],
      },
      office: {
        brand: ['0'],
        wireless: ['True'],
        bass_heavy: ['False'],
        microphone_score: ['7.0'],
        noise_cancelling: ['True'],
        type: ['over-ear'],
      },
      gaming: {
        brand: ['0'],
        wireless: ['True'],
        bass_heavy: ['0'],
        microphone_score: ['7.5'],
        noise_cancelling: ['False'],
        type: ['over-ear'],
      },
    },
    'tv': {
      show_tip: 2,
      usage: ['mixed_use'],
      mixed_use: {
        brand: ['0'],
        resolution: ['4K'],
        pixel_type: ['LED'],
        viewing_angle: ['0']
      },
      sports: {
        brand: ['0'],
        resolution: ['4K'],
        pixel_type: ['LED'],
        viewing_angle: ['0']
      },
      video_games: {
        brand: ['0'],
        resolution: ['4K'],
        pixel_type: ['LED'],
        viewing_angle: ['0']
      },
      movies: {
        brand: ['0'],
        resolution: ['4K'],
        pixel_type: ['LED'],
        viewing_angle: ['0']
      },
      tv_shows: {
        brand: ['0'],
        resolution: ['4K'],
        pixel_type: ['LED'],
        viewing_angle: ['0']
      },
    },
    'pickleball-paddle': {
      playstyle: ['balanced'],
      show_tip: 3,
      control: {
        core_thickness: ['thick'],
        shape: ['classic', 'elongated'],
        weight: ['midweight'],
        handle_length: ['medium', 'long'],
        face_material: ['carbon_fiber'],
        edge_type: ['edge_guard'],
        brand: ['0']
      },
      balanced: {
        core_thickness: ['standard', 'thick'],
        shape: ['classic', 'elongated'],
        weight: ['midweight'],
        handle_length: ['medium' , 'long'],
        face_material: ['carbon_fiber'],
        edge_type: ['edge_guard'],
        brand: ['0']
      },
      power: {
        core_thickness: ['thin', 'standard'],
        shape: ['elongated'],
        weight: ['midweight'],
        handle_length: ['medium', 'long'],
        face_material: ['carbon_fiber', 'fiber_glass'],
        edge_type: ['edge_guard'],
        brand: ['0']
      }
    },
  },

}
