import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { PAGEURL } from './../constants'
import {
  Home,
  Bulking,
  Barbell,
  ProductPicker,
  SavedResults,
  DefaultNav,
  About
} from './../components'
import Router from 'next/router'
// import { useDropdownMenu } from "react-overlays";
import Footer from './../components/common/Footer.js'
import services from './../components/services'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify';

toast.configure()
let localStorage = null
if (typeof window !== "undefined") {
  localStorage = window.localStorage
}

function DefaultLayout(props) {
  const [logged_in, set_logged_in] = useState(false);
  const router = useRouter();
  useEffect(() => {
    if (localStorage) {
      set_logged_in(localStorage.getItem('token') ? true : false)
    }
  }, [])

  const handlelogout = () => {
    // console.log('LOG OUT')
    localStorage.removeItem('token');
    set_logged_in(false)
    // this.props.history.push('/')
    router.push('/')
  }

  const handlelogin = async (values, token=null) => {
    if (token) {
      localStorage.setItem('token', token);
      set_logged_in(true)
    } else {
      const response = await services.post_v2("token-auth/", values)
      if (response.token) {
        localStorage.setItem('token', response.token);
        set_logged_in(true)
      } else {
        return false
      }
    }
    toast('You are logged in!')
    // return(response)
  };

  const setLoggedIn = () => {
    set_logged_in(true)
  }
  return (
    <div>

    </div>
  )
}

// DefaultLayout.propTypes = {
//   history: PropTypes.object,
//   match: PropTypes.object,
//   // store: PropTypes.object
// }

export default DefaultLayout
