import React from 'react';

function Circles () {

  return (
    <div>
      <div className="wrap">
        <div className="cube">
          <div id="circle1" className="circle circle1"></div>
          <div id="circle2" className="circle circle2"></div>
          <div id="circle3" className="circle circle3"></div>
          <div id="circle4" className="circle circle4"></div>
          <div id="circle5" className="circle circle5"></div>
          <div id="circle6" className="circle circle6"></div>
          <div id="circle7" className="circle circle7"></div>
          <div id="circle8" className="circle circle8"></div>
          <div id="circle9" className="circle circle9"></div>
          <div id="circle10" className="circle circle10"></div>
          <div id="circle11" className="circle circle11"></div>
          <div id="circle12" className="circle circle12"></div>
          <div id="circle13" className="circle circle13"></div>
          <div id="circle14" className="circle circle14"></div>
          <div id="circle15" className="circle circle15"></div>
          <div id="circle16" className="circle circle16"></div>
          <div id="circle17" className="circle circle17"></div>
          <div id="circle18" className="circle circle18"></div>
          <div id="circle19" className="circle circle19"></div>
        </div>
      </div>
      <div id="circles-tagline" className="circles-tagline">Simplify Your Search For The Perfect Product</div>
    </div>
  )
}

export default Circles
