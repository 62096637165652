import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
// import { PAGEURL } from './../constants'
// import {
//   DefaultNav,
// } from './../components'
import Router from 'next/router'
// import { useDropdownMenu } from "react-overlays";
// import Footer from './../components/common/Footer.js'
// import services from './../components/services'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify';

toast.configure()
let localStorage = null
if (typeof window !== "undefined") {
  localStorage = window.localStorage
}

function EmptyLayout(props) {

  const router = useRouter();



  try {
    if (router && router.query) {
      if (router.query.src) {
        if (typeof window !== "undefined") {
          window.localStorage.setItem('source', router.query.src || '')
        }
      }
      if (router.query.c) {
        if (typeof window !== "undefined") {
          window.localStorage.setItem('campaign', router.query.c || '')
        }
      }
    }
  } catch (e) {
    logError(e, "window.localStorage.setItem")
  }

  return (
    <div>
        <div>
          {
            React.cloneElement(
              props.children,
              {
                logged_in: false,
                handlelogin: null,
                handlelogout: null,
                router: router
              }
            )
          }
        </div>
    </div>
  )
}

// DefaultLayout.propTypes = {
//   history: PropTypes.object,
//   match: PropTypes.object,
//   // store: PropTypes.object
// }

export default EmptyLayout
