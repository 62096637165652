import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Nouislider from "@x1mrdonut1x/nouislider-react";
import "nouislider/distribute/nouislider.css";
import 'react-toastify/dist/ReactToastify.css'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import Switch from './Switch.js'
import services from '../services'
import { APIURL } from './../../constants'
import {
  formatIN } from './widgets/formats'

const MAX_MIN_RANGE = 188

const START_SIZE = [50,60]
const MIN_SIZE = 40
const MAX_SIZE = 98

toast.configure()

class TvSliders extends Component {
  constructor (props) {
    super(props)
    const td = this.props.topic_data
    let start_values = START_SIZE
    if (td && td[2] && td[2].value) {
      if (td[2].value.length === 1) {
        start_values = [td[2].value[0], td[2].value[0]]
      } else {
        let min = Math.min.apply(Math, td[2].value)
        let max = Math.max.apply(Math, td[2].value)
        start_values = [min, max]
      }
    }
    this.state = {
      show_sliders: true,
      min: MIN_SIZE,
      max: MAX_SIZE,
      start: start_values,
      lowerSize: 0,
      upperSize: 0,
      ref: null,
      rendering: false,
    }
  }

  checkIfValue (td, key) {
    return (td && td[2].values )
  }

  // componentDidMount = () => {
  //
  // }
  //
  // componentDidUpdate = () => {
  //
  // }

  createRange = (min_size, max_size) => {
    const {lowerSize, upperSize} = this.state
    var sizeList = [];
    if (min_size && max_size) {
      for (let i = min_size; i <= max_size; i++) {
          sizeList.push(String(parseInt(i)));
      }
    }
    if (this.state.show_sliders) {
      this.props.setWidgetValues(sizeList, null, {}, false, null)
    }
  }

  checkSizes1 = (value) => {
    let size_range = document.getElementById('size_range')
    if (size_range) {
      const {max} = this.state
      let tooltips = size_range.getElementsByClassName('noUi-tooltip')
      let left_tooltip = tooltips[0]
      let right_tooltip = tooltips[1]
      if(value[1] - value[0] <= 8) {
        let connect = document.getElementsByClassName('noUi-connects')
        let offset_left = Math.round(connect[0].getBoundingClientRect().width) / 60 / 2 * -(value[1] - value[0]) + "px"
        if (Math.round(connect[0].getBoundingClientRect().width) / 2 < 30 && parseInt(value[1]) === max && value[0] !== value[1]) {
          offset_left = "-30px"
        }
        left_tooltip.classList.add('display-none')
        right_tooltip.classList.add('large')
        let calc_string = "calc(50% + " + offset_left+")"
        right_tooltip.style.setProperty('left', calc_string)
        if (value[0] === value[1]) {
          right_tooltip.innerHTML = (Math.floor(value[0]) + '"' + (parseInt(value[1]) === max ? "+" : ""))
        } else {
          right_tooltip.innerHTML = (Math.floor(value[0]) + '" - ' + Math.floor(value[1])+ '"' + (parseInt(value[1]) === max ? "+" : ""))
        }
      } else {
        left_tooltip.classList.remove('display-none')
        right_tooltip.classList.remove('large')
        right_tooltip.style.setProperty('left', '50%')
        if (parseInt(value[1]) === max) {
          right_tooltip.innerHTML = (Math.floor(value[1])+ '"+')
        } else {
          right_tooltip.innerHTML = (Math.floor(value[1])+ '"')
        }
      }

      this.setState({lowerSize: parseInt(value[0]), upperSize: parseInt(value[1])})
      this.createRange(parseInt(value[0]), parseInt(value[1]), null, null)
    }
  }


  render () {
    const {
      min,
      max,
      start,
      rendering,
    } = this.state
    return (
      <div className='snowboard-widget'>
        <div class="calc-sliders tv">
        {rendering && (
          <div className="spinner-container">
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        )}
        {!rendering && (
            <div className={'slider-fade ' + classNames({'display-none': !this.state.show_sliders})}>
              <div className="slider-container bottom-sliders">
                <Nouislider
                  id='size_range'
                  animate= {true}
                  onUpdate={(e) => {this.checkSizes1(e)}}
                  step={1}
                  range={{ min: min, max: max }}
                  start={start}
                  tooltips={[formatIN(max), formatIN(max)]}
                  connect={true}
                  instanceRef={(instance) => {
                    if (instance && !this.state.ref) {
                      this.setState({ref: instance})
                    }
                  }}
                />
                <div className="slider-label">TV Size (inches)</div>
              </div>
            </div>
        )}
        </div>
      </div>
    )
  }
}

TvSliders.propTypes = {
  topic_data: PropTypes.object,
  goBackToQuestionX: PropTypes.func,
}

export default TvSliders
