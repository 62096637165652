import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types'
import { PAGEURL } from './../constants'
import {
  DefaultNav,
} from './../components'
import Router from 'next/router'
// import { useDropdownMenu } from "react-overlays";
import Footer from './../components/common/Footer.js'
import services from './../components/services'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppContext from "./../components/common/AppContext.js"

const isServer = typeof window === 'undefined';

toast.configure()
// if (typeof window !== "undefined") {
//   localStorage = window.localStorage
// }


function DefaultLayout(props) {
  const router = useRouter();
  // console.log(props)
  const test = useContext(AppContext);

  // useEffect(() => {
  //   console.log(localStorage)
  // }, []);

  const [logged_in, set_logged_in] = useState("false")

  const initialize = () => {
    if (isServer) {
      return "false"
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem('token')
      // Parse stored json or if none return initialValue
      return item ? "true" : "false"
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return "false"
    }
  }

  useEffect(() => {
    if (!isServer) {
      set_logged_in(initialize());
    }
    if (router.query.src) {
      localStorage.setItem('source', router.query.src)
    } else {
      localStorage.setItem('source', '')
    }
    if (router.query.src) {
      localStorage.setItem('campaign', router.query.c)
    } else {
      localStorage.setItem('campaign', '')
    }
  }, [])

  const handlelogout = () => {
    // console.log('LOG OUT')
    localStorage.removeItem('token');
    set_logged_in("false")
    // this.props.history.push('/')
    router.push('/')
  }

  const handlelogin = async (values, token=null) => {
    if (token) {
      localStorage.setItem('token', token);
      set_logged_in('true')
    } else {
      const response = await services.post_v2("token-auth/", values)
      if (response.token) {
        localStorage.setItem('token', response.token);
        if (localStorage.setItem('', token)) {
          response1 = await services.post_v2("token-auth/", values)
        }
        set_logged_in("true")
      } else {
        return false
      }
    }
    toast('You are logged in!')
    // return(response)
  }
  // console.log(test)
  // const { logged_in, handlelogin, handlelogout } = props
  // useEffect(() => {
  //   if (localStorage) {
  //     set_logged_in(localStorage.getItem('token') ? true : false)
  //   }
  // }, [])
  // const x = useContext(AppContext)
  // console.log(x)
  // const setLoggedIn = () => {
  //   set_logged_in(true)
  // }
  // let localStorage = null
  // if (typeof window !== "undefined") {
    // localStorage = window.localStorage
  // }

  // console.log(localStorage && localStorage.getItem('token') ? true : false)



  // )


  let extraPadding = false
  if (router.pathname.indexOf('recommendation-engine') > -1 || router.pathname.indexOf('product-picker') > -1) {
    extraPadding = true
  }

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH}>
      <AppContext.Provider value = {{
        logged_in: logged_in,
        handlelogin: handlelogin,
        handlelogout: handlelogout
      }}>
        <div>
          <DefaultNav
            router={router}
          />
            <div>
              {
                React.cloneElement(
                  props.children,
                  {
                    router: router,
                    logged_in: logged_in,
                  }
                )
              }
            </div>
          <Footer extraPadding={extraPadding} router={router}/>
        </div>
      </AppContext.Provider>
    </GoogleOAuthProvider>

  )
}

// DefaultLayout.propTypes = {
//   history: PropTypes.object,
//   match: PropTypes.object,
//   // store: PropTypes.object
// }

export default DefaultLayout
