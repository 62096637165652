import React from "react";
// import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

class LazyImg extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    effect: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    classNames: PropTypes.string,
    onClick: PropTypes.func
  }

  render () {
    const { width, effect, src, height, classNames, onClick, alt } = this.props
    return (
        <LazyLoadImage
          height={height}
          effect={effect}
          src={src}
          alt={alt}
          width={width}
          wrapperClassName={classNames}
          onClick={() => {
            if(onClick) {
              onClick()
            }
          }} />
    )
  }
}

export default LazyImg
