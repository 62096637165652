import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Nouislider from "@x1mrdonut1x/nouislider-react";
import 'react-toastify/dist/ReactToastify.css'
import "nouislider/distribute/nouislider.css"
import classNames from 'classnames'
import { toast } from 'react-toastify'
import Switch from './Switch.js'
import services from '../services'
import { APIURL } from './../../constants'
import {
  formatHeightMetric,
  formatHeight } from './widgets/formats'

const MAX_MIN_RANGE = 190
const QN = 5

toast.configure()

class MountainBikeSizeWidget extends Component {

  constructor (props) {
    super(props)
    const td = this.props.topic_data
    // console.log(td[QN].input_values['height'])
    this.state = {
      height: this.checkIfValue(td, 'height') ? td[QN].input_values['height'] : 0,
      startHeight: this.checkIfValue(td, 'height') ? parseInt(td[QN].input_values['height']) : 67,
      metricHeight: this.checkIfValue(td, 'metricHeight') ? parseInt(td[QN].input_values['metricHeight']) : null,
      start_mHeight: this.checkIfValue(td, 'metricHeight') ? parseInt(td[QN].input_values['metricHeight']) : null,
      show_sliders: td && td[QN].input_values ? true : false,
      min: 0,
      max: 0,
      lowerSize: 0,
      upperSize: 0,
      ref: null,
      rendering: true,
      show_metric: td && td[QN].input_values && td[QN].input_values['show_metric'] ? true : false,
      user_info_save: false
    }
  }

  checkIfValue (td, key) {
    return (td && td[QN].input_values && td[QN].input_values[key] && td[QN].input_values[key])
  }

  componentDidMount = () => {
    const td = this.props.topic_data
    if (this.props.logged_in === 'true') {
      services.get_v2(APIURL.USER_PROFILE, {params: {}}).then((response) =>{
        let user_info = response
        let show_metric = user_info.use_metric
        if (td && td[5].input_values && td[5].input_values['show_metric'] === false) {
          show_metric = false
        } else if (td && td[5].input_values && td[5].input_values['show_metric'] === true) {
          show_metric = true
        }
        let calculate_needed = false
        let user_info_save = true
        if (user_info.height && !this.checkIfValue(td, 'height')) {
          calculate_needed = true
          user_info_save = false
        }
        this.setState({
          user_profile: true,
          startHeight: this.checkIfValue(td, 'height') ? td[QN].input_values['height'] : false         || user_info.height        || 67,
          start_mHeight: this.checkIfValue(td, 'metricHeight') ? td[QN].input_values['metricHeight'] : false || user_info.metric_height || 171,
          show_metric: show_metric,
          calculate_needed: calculate_needed,
          user_info_save: user_info_save
        })
      })
    } else {
      if (typeof window !== "undefined") {
        let user_info = JSON.parse(window.localStorage.getItem('calculator_values'))
        console.log(user_info)
        if (user_info) {
          let show_metric = user_info['show_metric']
          if (td && td[5].input_values && td[5].input_values['show_metric'] === false) {
            show_metric = false
          } else if (td && td[5].input_values && td[5].input_values['show_metric'] === true) {
            show_metric = true
          }
          let calculate_needed = false
          if (user_info.height && !this.checkIfValue(td, 'height')) {
            calculate_needed = true
          }
          this.setState({
            user_profile: true,
            startHeight: this.checkIfValue(td, 'height') ? td[QN].input_values['height'] : false         || user_info.height       || 67,
            start_mHeight: this.checkIfValue(td, 'metricHeight') ? td[QN].input_values['metricHeight'] : false || user_info.metricHeight || 171,
            show_metric: show_metric,
            calculate_needed: calculate_needed,
          })
        }
      }
    }
  }

  componentDidUpdate = () => {
    if (this.state.calculate_needed) {
      let user_info = JSON.parse(window.localStorage.getItem('calculator_values'))
      this.calculateSizeRange(this.state.startHeight)
    }
  }

  createRange = (min_size, max_size) => {
    // console.log('createRange')
    const {lowerSize, upperSize} = this.state
    var sizeList = [];
    if (min_size && max_size) {
      for (let i = min_size; i <= max_size; i++) {
          sizeList.push(String(parseInt(i)));
      }
    } else if (lowerSize && upperSize) {
      for (let i = lowerSize; i <= upperSize; i++) {
          sizeList.push(String(parseInt(i)));
      }
    }
    if (this.state.show_sliders) {
      this.props.setWidgetValues(sizeList, null, {
        height: this.state.height,
        show_metric: this.state.show_metric,
        metricHeight: this.state.metricHeight
      }, false, this.state.user_info_save)
    }
  }

  getStartingRange = (topic_data) => {
    // console.log('getStartingRange')
    let min = 58
    let max = 76
    this.setState({
      min: min,
      max: max,
      rendering: false
    })
    this.createRange(min, max)
  }

  calculateSizeRange = (height) => {
    // console.log('calculateSizeRange')
    if (this.state.calculate_needed) {
      this.setState({calculate_needed: false})
    }

    const { topic_data } = this.props
    let min_range = 0
    let max_range = 0
    let values = []
    height = parseInt(height)
    if (height < 62) {
      values.push('S')
    } else if (height === 62) {
      values.push('SX')
      values.push('S')
    } else if (height < 66) {
      values.push('S')
    } else if (height === 66) {
      values.push('S')
      values.push('M')
    } else if (height < 70) {
      values.push('M')
    } else if (height === 70) {
      values.push('M')
      values.push('L')
    } else if (height < 74) {
      values.push('L')
    } else if (height == 74) {
      values.push('L')
      values.push('XL')
    } else {
      values.push('XL')
    }

    this.props.setWidgetValues(
      values,
      null,
      {
        height: height,
        show_metric: this.state.show_metric,
        metricHeight: this.state.metricHeight
      },
      true,
      this.state.user_info_save
    )

  }

  toggleSwitch = () => {
    let {metricHeight, startHeight} = this.state
    if (!this.state.show_metric) {
      // let start_mWeight = this.state.metricWeight ? this.state.metricWeight : Math.round(this.state.startWeight / 2.2)
      let start_mHeight = this.state.metricHeight ? this.state.metricHeight : Math.round(this.state.startHeight * 2.54)
      this.setState({
        show_metric: !this.state.show_metric,
        // start_mWeight: start_mWeight,
        start_mHeight: start_mHeight,
      })
    } else {
      this.setState({show_metric: !this.state.show_metric})
    }
  }

  render () {
    const {
      height,
      // weight,
      min,
      max,
      rendering,
      // startWeight,
      startHeight,
      // metricWeight,
      metricHeight,
      start_mHeight,
      // start_mWeight
    } = this.state
    if (min === 0) {
      this.getStartingRange(this.props.topic_data)
    }

    return (
      <div>
      {rendering && (
        <div className="spinner-container">
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      )}
      {!rendering && (
        <div className="snowboard-widget">
          <div className="calc-sliders">
          <h1 id="size-calc">Size Calculator</h1>
            <Switch containerClasses={"switch-container mt-sm"} label={'Use Metric Units'} checked={this.state.show_metric} switchChange={this.toggleSwitch}/>
            <div>
              {!this.state.show_metric && (
                <div>
                  <div className="slider-container">
                    <Nouislider
                      onUpdate={(value) => {this.setState({height: value, metricHeight: Math.round((value * 2.54))})}}
                      step={1}
                      range={{ min: 58, max: 76 }}
                      start={[startHeight]}
                      tooltips={formatHeight(76)}
                      connect
                    />
                    <div className="slider-label">Height</div>
                  </div>
                </div>
              )}
            </div>
            <div>
              {this.state.show_metric && (
                <div>
                  <div className="slider-container">
                    <Nouislider
                      onUpdate={(value) => {
                        let h = [Math.round(value / 2.54)]
                        this.setState({metricHeight: value, height: h})
                      }}
                      step={1}
                      range={{ min: 148, max: 194 }}
                      start={[start_mHeight]}
                      tooltips={formatHeightMetric(194)}
                      connect
                    />
                    <div className="slider-label">Height</div>
                  </div>
                </div>
              )}
            </div>
            <div className="btn-center-container">
              <button className={"action-btn-gld continue-btn btn-m10 small"} onClick={
                () => {
                  this.calculateSizeRange(height)
                  let element = document.getElementById('bottom-div')
                  if (element.getBoundingClientRect().bottom + 50 >= (window.innerHeight || document.documentElement.clientHeight)) {
                    let element1 = document.getElementById('size-calc')
                    element1.scrollIntoView({'behavior': 'smooth'})
                  }
                }}>Get My Sizes
              </button>
            </div>
          </div>
          <div id="bottom-div"></div>
        </div>
      )}
      </div>
    )
  }
}

MountainBikeSizeWidget.propTypes = {
  topic_data: PropTypes.object,
  goBackToQuestionX: PropTypes.func,
  makeSelectionMultiple: PropTypes.func,
  logged_in: PropTypes.func,
}

export default MountainBikeSizeWidget
