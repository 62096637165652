import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

class About extends React.Component {

  componentDidMount = async () => {
  }

  render () {
    return (
      <div className="home">
        <div className="home-container">
          <div className="about">
            Information is everywhere on the internet.
            This is a great thing; until it isn’t.
            When it comes to picking products these days, the problem is no longer a lack of information, it’s sifting through the information overload.
            And then there’s the issue of incentives and misinformation.
            <br/>
            <br/>
            There are hundreds of review sites for every type of product and almost all suffer from the same fatal flaw.
            The reviewer has zero incentive to give anything a bad review.
            They make their money when people come to their site, watch their review, click their link, and purchase the product.
            With this model there is never an incentive to leave a bad review and risk losing a potential customer.
            <br/>
            <br/>
            SimplyBuy changes that.
            SimplyBuy provides you with the necessary information to ensure you are buying the right product for you.
            We use the information you supply to show you the best products that suit your needs.
            Our goal is to direct you to the same product you would eventually buy after 5 hours of research, in as little as five minutes.
            Our product guides are crafted or endorsed by industry experts to ensure the information on SimplyBuy is accurate and can be trusted.
            <br/>
            <br/>
            <div className="psg-container pt-none">
              See the SimplyBuy process for yourself. Select a product to get started
              <div className="products-flex">
                <div className="product-container">
                  <Link href={PAGEURL.PRODUCT_PICKER('barbell', '0')}>
                      <div className="product-option">
                        Barbells
                      </div>
                  </Link>
                </div>
                <div className="product-container">
                  <Link href={PAGEURL.PRODUCT_PICKER('snowboard', '0')}>
                      <div className="product-option">
                        Snowboards
                      </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default About
