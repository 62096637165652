import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { APIURL } from './../../constants'
import services from '../services'
import classNames from 'classnames'
import { add_unit } from '../utils'
import DataList from './DataList.js'
import { GrClose } from '@react-icons/all-files/gr/GrClose'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LazyImg from './LazyImg.js'
import { logError } from '../Logger'

toast.configure()

class EngineInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounting: true,
      user_input: {value: '', label: ''},
      initial_load_complete: false,
      available_engines: props.available_engines
    }
  }

  componentDidMount = async () => {
  }

  // componentDidUpdate = async () => {

  // }

  render () {
    let options = []
    for (let option in this.state.available_engines) {
      options.push(<option value={this.state.available_engines[option].label}/> )
    }
    return (
      <div className='simplybuy-container dark'>
          <div className="saved-results-container">
            <h1 className={"saved-results-header no-margin"}>AI Recommenation Engines</h1>
            <div key="beta" className="beta-tag1 mb-xl"><span>Beta</span></div>
            <DataList options={this.state.available_engines} router={this.props.router}/>
          </div>
      </div>
    )
  }
}

EngineInput.propTypes = {
  available_engines: PropTypes.array
}

// export default SavedResult
export default EngineInput
