import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { FaRegUserCircle } from '@react-icons/all-files/fa/FaRegUserCircle'
import Dropdown from "./Dropdown.js"
import LoginModal from "./LoginModal.js"
import { PAGEURL } from "./../../constants"
// import { withRouter } from 'react-router-dom'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Image from 'next/image'
import services from '../services'
import { APIURL } from './../../constants'
import classNames from 'classnames'
// import ReactGA from 'react-ga'


// @inject('store')
// @observer
export default function Loading(props) {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname)
  // });

  // React.useEffect( async () => {
  // }, []);

  // const { logged_in } = props
  return (
    <>
      {props.loading && (
        <div className={"spinner-container " + classNames({'not-full': props.not_full})}>
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      )}
      {!props.loading && (
        <div>
        {props.children}
        </div>
      )}
    </>
  )
}

// DefaultNav.propTypes = {
//   loading: PropTypes.bool,
//   full: PropTypes.bool,
// }

// export default Loading
